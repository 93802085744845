.quote {
  display: flex;
  gap: 14px;

  @media (min-width: 768px) {
    gap: 20px;
  }

  &__media {
    width: 156px;
    flex-shrink: 0;
  }

  &--song {
    .quote__media {
      width: 60px;
      @media (min-width: 768px) {
        width: 108px;
      }
    }
  }

  &__text {
    display: flex;
    gap: 6px;

    @media (min-width: 768px) {
      gap: 10px;
    }

    &::before {
      content: '';
      display: block;
      width: 12px;
      height: 12px;
      flex-shrink: 0;
      background-image: url('/img/quote-opening.svg');
      background-repeat: no-repeat;
      background-size: 100%;

      @media (min-width: 768px) {
        width: 22px;
        height: 22px;
      }
    }

    &-title {
      font-size: 16px;
      font-style: italic;
      font-weight: 500;
      line-height: 1.4;

      > * {
        display: inline;
      }

      .quote__closing-icon {
        display: inline-block;
        width: 12px;
        height: 12px;
        background-image: url('/img/quote-closing.svg');
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: bottom center;
        transform: translateY(4px);
        margin-left: 5px;
      }

      @media (min-width: 768px) {
        font-size: 22px;

        .quote__closing-icon {
          width: 18px;
          height: 18px;
          margin-left: 10px;
        }
      }
    }

    &-description {
      font-size: 12px;
    }

    a {
      display: none;

      @media (min-width: 768px) {
        display: inline-block;
      }
    }
  }

  &-container {
    a.quote-cta {
      background-color: rgba(50, 136, 238, 1);
      color: #fff;
      font-weight: bold;
      padding-left: 18px;
      padding-right: 18px;
      padding-top: 10px;
      padding-bottom: 10px;
      border-radius: 6px;
      font-size: 14px;
      margin-top: 10px;
      text-align: center;

      &:hover {
        text-decoration: none !important;
      }
    }

    & > a {
      display: block;

      @media (min-width: 768px) {
        display: none;
      }
    }
  }
}
