.tag {
  height: 26px;
  width: fit-content;
  padding: 7px 20px;
  font-size: 10px;
  font-weight: 500;
  line-height: 120%;
  text-transform: uppercase;
  background-color: $grey;
  margin-right: 2px;
  margin-bottom: 2px;
  @include flex-center;
  &-sm {
    @extend .tag;
    padding: 6px 6px 3px;
    height: unset;
    font-size: 12px;
    font-weight: 600;
  }
}
.tags-wrapper {
  display: flex;
  max-width: 70%;
  flex-wrap: wrap;
}

.role {
  &__tags-area {
    min-height: 54px;
    height: fit-content;
    padding-left: 20px;
    font-size: 13px;
    color: #333;
    border-radius: 5px !important;
  }
  &__tag {
    background-color: #169bd5;
    border-radius: 38px;
    color: $white;
    display: inline-block;
    font-family: 'Arial Regular', Arial, sans-serif;
    font-size: 16px;
    font-weight: 600;
    margin: 4px 5px 4px 0;
    padding: 10px 20px;
  }
  &__clear {
    padding-left: 15px;
    img {
      width: 11px;
      display: inline-block;
      margin-bottom: 4px;
    }
  }
  &__suggestion {
    color: #169bd5;
    background-color: #000;
    border-radius: 6px;
    margin-right: 11px;
    padding: 12px 8px 10px;
    line-height: 10px;
    margin-bottom: 12px;
    display: inline-block;
    font-size: 15px;
    font-weight: 700;
    &:hover {
      color: white;
      text-decoration: none !important;
    }
  }
}

.react-tagsinput {
  background-color: #fff;
  border-radius: 5px;
  overflow: hidden;
  padding-left: 10px;
  padding-top: 0px;
}

.react-tagsinput--focused {
  border-color: #a5d24a;
}

.react-tagsinput-tag {
  background-color: #169bd5;
  border-radius: 38px;
  color: $white;
  display: inline-block;
  font-family: 'Arial Regular', Arial, sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 10px 20px;
}

.react-tagsinput-remove {
  cursor: pointer;
  font-weight: bold;
}

.react-tagsinput-tag a::before {
  content: url('/img/bin-tag.png');
  padding-left: 15px;
}

.react-tagsinput-input {
  background: transparent;
  border: 0;
  color: #777;
  font-family: sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 0px;
  margin-top: 1px;
  outline: none;
  padding: 5px;
  width: 80px;
}
