.song-calendar {
  &__header {
    &__icon {
      @include mobile {
        display: none;
      }
    }
    &__container {
      padding-top: 30px;
      justify-content: space-between;
      display: flex;
      margin-top: 0;
      @include mobile {
        padding-bottom: 30px;
      }
    }
    &__text {
      font-size: 34px;
      margin-left: 20px;
      @include mobile {
        font-size: 28px;
      }
    }
    &__description {
      max-width: 563px;
      font-weight: 400;
      font-size: 18px;
      @include mobile {
        font-size: 16px;
        margin-left: 20px;
        max-width: 50%;
      }
    }
    &__banner {
      margin-top: -110px;
      @include mobile {
        display: none;
        max-height: 149px;
        margin-top: -30px;
        margin-right: -22px;
        transform: translateX(-11px);
      }
    }
  }
  &__event {
    width: 100%;
    display: flex;
    font-size: 14px;
    background-color: $light-gray;
    margin-bottom: 25px;
    &:last-child {
      margin-bottom: 0;
    }
    a {
      color: $moderate-cyan;
      text-decoration: underline !important;
    }
    &__date {
      background-color: $dark-gray;
      width: 75px;
      height: 75px;
      font-size: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      flex-shrink: 0;
    }
    &__content {
      color: $arsenic;
      line-height: 22px;
      p {
        p {
          margin-bottom: 0 !important;
          display: inline;
        }
      }
      @include mobile {
        font-size: 13px;
      }
    }
  }
  &__banner {
    @include mobile {
      background-image: url('/img/song-calendar-banner.png');
      background-repeat: no-repeat;
      background-position: 115% 100%;
      background-size: 60%;
    }
  }
}
.eventpage {
  &__header {
    background-color: $arsenic;
    &__title {
      font-size: 48px;
      @include sm {
        font-size: 24px;
        margin-bottom: 4px;
      }
    }
    &__desc {
      font-size: 14px;
      @include sm {
        font-size: 10px;
      }
    }
    &__dropdown {
      @include tablet {
        top: unset;
        bottom: 0;
      }
    }
  }
}
.collections {
  &__banner {
    @include mobile {
      background-image: url('/img/collections_banner.png');
      background-repeat: no-repeat;
      background-position: 180% 100%;
      background-size: 70% 80%;
    }
  }
}
