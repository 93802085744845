.main {
  flex-grow: 1;
  margin-left: 323px;
  overflow: hidden;
  min-height: 100vh;
  @include laptop-sm {
    margin-left: 0;
  }
  &__nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 28px 40px 20px 0;
    @include laptop-sm {
      padding: 0;
    }
    @include laptop-sm {
      display: none;
    }
    &__info {
      display: flex;
      align-items: center;
      &__school {
        max-width: 138px;
        font-size: 11px;
        text-align: right;
        margin-bottom: 0;
        margin-right: 12px;
      }
      &__img {
        display: block;
        width: 30px;
        height: 30px;
        border-radius: 100%;
        margin-right: 12px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
      &__avatar,
      &__notifications {
        display: flex;
        align-items: center;
        justify-content: center;
        &.show {
          background: $cello;
          border-radius: 10px 10px 0 0;
          @include laptop-sm {
            width: 323px;
            align-items: flex-start;
            background: #252e36;
          }
        }
        .dropdown-toggle {
          display: block;
          padding: 9px 10px 9px 11px;
          &::after {
            content: none;
          }
          img {
            width: 30px;
            height: 30px;
            border-radius: 100%;
            object-fit: cover;
            @include laptop-sm {
              display: inline;
            }
          }
          @include laptop-sm {
            padding-left: 25px;
          }
        }
        .dropdown-menu {
          width: max-content;
          background: $cello;
          border: $cello;
          margin-top: 0px;
          border-radius: 10px 0 10px 10px;
          padding: 0;
          @include laptop-sm {
            background: #252e36;
            width: 323px;
            border-radius: 0 0 10px 10px;
            left: -5px !important;
            box-shadow: 1px 12px 13px -7px rgba(0, 0, 0, 0.3);
          }
        }
        .dropdown-menu-notifications {
          width: 464px !important;
          @include mobile {
            width: 100vw !important;
          }
        }
        .dropdown-item {
          display: flex;
          align-items: center;
          padding: 12px 20px;
          &:first-child {
            padding-top: 24px;
          }
          &:last-child {
            padding-bottom: 20px;
          }
          &:hover,
          &:active {
            background-color: unset;
            p {
              color: $white;
            }
          }
          img {
            margin-right: 24px;
            width: 22px;
          }
          p {
            font-size: 14px;
            font-weight: 500;
            line-height: 100%;
            color: $grey-chateau;
            margin-bottom: 0;
            margin-top: 4px;
            @include laptop-sm {
              font-size: 11px;
              margin-top: 2px;
              font-weight: 400;
            }
          }
          @include laptop-sm {
            padding: 10px 20px;
          }
        }
        .logo {
          width: 22px;
          height: 22px;
          border-radius: 100%;
          object-fit: cover;
        }
        @include laptop-sm {
          justify-content: unset;
        }
      }
      &__notifications {
        &.show {
          @include laptop-sm {
            width: unset;
            background: #0d1317;
          }
        }
        .dropdown-toggle {
          img {
            border-radius: unset;
            width: 27px;
            height: 30px;
          }
          @include laptop-sm {
            padding-left: 10px;
          }
        }
      }
    }
    &__mobile {
      display: none;
      height: 50px;
      padding: 10px;
      @include laptop-sm {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      &__avatar {
        height: 30px;
        width: 30px;
        border-radius: 100%;
        @include flex-center;
        img {
          width: 100%;
          height: 100%;
          object-position: center;
          object-fit: cover;
          border-radius: 50%;
        }
      }
      &__logo {
        width: 30px;
        @include flex-center;
      }
      &__search {
        width: 30px;
        @include flex-center;
      }
    }
  }
  &__container {
    max-width: 1084px;
    // margin: 0 auto;
    margin-top: 40px;
    position: relative;
    padding: 0 40px;
    @include laptop-sm {
      max-width: unset;
      padding: 0;
      margin-top: 0px;
    }

    &--new-sparks {
      max-width: 1200px;

      @include tablet {
        overflow: auto;

        > * {
          min-width: 1200px;
        }
      }
    }
  }
}
