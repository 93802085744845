.button {
  width: fit-content;
  font-size: 13px;
  padding: 4px 18px;
  height: 35px;
  border-radius: 5px;
  border: 0;
  outline: 0 !important;
  @include flex-center;
  &__sm {
    @extend .button;
    font-size: 10px;
    padding: 4px 16px;
    height: 28px;
  }
  &__md {
    @extend .button;
    font-size: 12px;
    padding: 4px 13px;
    height: 33px;
  }
  &__lg {
    @extend .button;
    height: 38px;
  }
  &__xl {
    @extend .button;
    height: 48px;
  }
  &__form {
    @extend .button;
    height: 38px;
    padding: 4px 10px;
    @include mobile {
      padding: 4px 14px;
    }
  }
  &__turquoise {
    background-color: $turquoise-medium;
    color: $white !important;
  }
  &__blue-curious {
    background-color: $blue-curious;
    color: $white !important;
  }
  &__green-meadow {
    background-color: $green-meadow;
    color: $white !important;
    &:disabled {
      background-color: #b8ffec !important;
      color: #999 !important;
      cursor: not-allowed !important;
    }
  }
  &__white {
    background-color: $white;
    color: $black !important;
    &-outline {
      background-color: transparent;
      color: $white !important;
      border: 2px solid $white;
    }
  }
  &__moderate-cyan {
    background-color: $moderate-cyan;
  }
  &__rounded {
    &__sm {
      @include flex-center;
      width: fit-content;
      font-size: 12px;
      height: 28px;
      border-radius: 15px;
      padding: 5px 16px 4px;
    }
  }
  &:disabled {
    background-color: #a5edf7;
    cursor: wait;
  }
  &__filter {
    padding: 12px 10px 10px;
    height: inherit;
  }
  &__nativities-cta {
    @extend .button;
    font-size: 12px;
    padding: 4px 13px;
    height: 33px;
    background-color: $moderate-cyan;
    margin-left: auto;
    color: $white;
    display: inline-flex;
    align-items: flex-end;
    &:hover {
      color: #eee;
    }
  }
  &__green-meadow,
  &__cerise-dark,
  &__cerise-dark-bg {
    @extend .button;
    background-color: $green-meadow;
    font-size: 14px;
    color: $white;
    border-radius: 10px;
    width: 256px;
    height: 54px;
  }
  &__cerise-dark {
    background-color: transparent;
    border: 1px solid $cerise-dark;
    color: $cerise-dark;
    font-weight: 700;
  }
  &__cerise-dark-bg {
    background-color: $cerise-dark;
    color: $white;
    font-weight: 700;
  }

  &__transparent {
    background-color: $body;
    &__green {
      @extend .button__transparent;
      color: $green-meadow;
    }
  }
  &__w-144,
  &__w-214,
  &__w-74,
  &__150 {
    width: 144px;
    font-size: 14px;
    padding: unset;
  }
  &__w-214 {
    width: 214px;
  }
  &__w-74 {
    width: 74px;
  }
  &__w-200 {
    width: 200px;
  }
  &__age-group-check {
    width: 41px;
    height: 38px;
    background-color: #55a35d;
    color: white;
    border-radius: 10px;
    font-size: 20px;
  }
}
