.singsubject {
  &__header {
    padding: 48px 0 51px;
    background: no-repeat url('/img/bearded-teacher.png');
    background-position: right bottom;
    background-size: 196px;
    @include laptop-sm {
      padding: 48px 16px 51px;
    }
    @include tablet {
      padding: 36px 16px 32px;
      background-size: 156px;
    }
    @include mobile {
      padding: 36px 16px 29px;
    }
    &__title {
      font-size: 36px;
      font-weight: 600;
      text-transform: uppercase;
      line-height: 1;
      margin-bottom: 23px;
      @include mobile {
        font-size: 20px;
        margin-bottom: 13px;
      }
    }
    &__text {
      font-size: 16px;
      margin-bottom: 37px;
      max-width: 608px;
      @include tablet {
        max-width: 500px;
      }
      @include mobile {
        font-size: 12px;
        max-width: 170px;
        margin-bottom: 20px;
      }
    }
    &__buttons {
      display: grid;
      grid-template-columns: repeat(2, max-content);
      column-gap: 12px;
      @include mobile {
        grid-template-columns: max-content;
        row-gap: 17px;
        column-gap: 0;
      }
    }
  }
  &__songs {
    &__find {
      @extend .assembly__find !optional;
      grid-template-columns: auto;
      max-width: 911px;
      @include laptop-sm {
        margin-bottom: 29px;
      }
      @include tablet {
        padding: 33px 16px 38px;
      }
      @include mobile {
        grid-template-columns: auto;
      }
    }
  }
  &__age-range {
    grid-template-columns: auto auto;
    @include mobile {
      grid-template-columns: auto;
    }
  }
}
