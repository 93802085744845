.cp {
  &__header {
    background-color: $cello;
    padding: 38px 0 26px;
    max-width: 1084px;
    @include laptop-sm {
      padding: 38px 16px 26px;
    }
    @include mobile {
      padding: 18px 16px;
    }
    &__breadcrumb {
      display: block;
      font-size: 14px;
      color: $white !important;
      margin-bottom: 40px;
      @include mobile {
        margin-bottom: 16px;
        padding-left: 10px;
      }
    }
    &__info {
      display: flex;
      justify-content: space-between;
      @include mobile {
        align-items: center;
      }
    }
    &__title {
      font-size: 36px;
      font-weight: 500;
      margin-bottom: 0;
      @include mobile {
        font-size: 18px;
      }
    }
  }
  &__container {
    max-width: 699px;
    margin: 24px auto;
    @include tablet {
      margin: 0 0 24px;
      max-width: unset;
    }
  }
  &__vid {
    &__thumb {
      position: relative;
      &__img {
        display: block;
        width: 100%;
      }
    }
    &__play {
      @include absolute-center;
      @include flex-center;
      width: 103px;
      @include mobile {
        width: 60px;
      }
      img {
        width: 100%;
      }
    }
    &__title {
      background-color: $cello;
      padding: 20px 28px;
      @include mobile {
        background-color: $cerise-light;
        padding: 15px 16px;
      }
      p {
        font-size: 18px;
        margin-bottom: 0;
        @include mobile {
          font-size: 14px;
        }
      }
    }
  }
  &__songchoice {
    @include mobile {
      margin-left: -5px;
      margin-right: -5px;
    }
    & > div {
      @include mobile {
        padding-left: 5px;
        padding-right: 5px;
      }
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}
.curriculum-grid {
  grid-template-columns: 2.8fr 1fr !important;
  @include mobile {
    grid-template-columns: 1fr !important;
    grid-template-rows: auto auto;
    row-gap: 7px;
  }
}
