.event-item {
  display: block;
  border-radius: 15px;
  overflow: hidden;
  color: #333;

  &:hover {
    text-decoration: none !important;
    color: #333;
  }

  &__content {
    padding: 10px;
    background-color: #fff;
    position: relative;

    &::before {
      content: '';
      display: block;
      position: absolute;
      height: 60px;
      left: 0;
      top: 0;
      right: 0;
      background-color: inherit;
      transform: translateY(-100%);
    }
  }

  img {
    position: relative;
    z-index: 1;
  }

  img + .event-item__content {
    padding-top: 20px;
  }

  &--external {
    .event-item__title {
      font-weight: bold;
      font-size: 12px;
    }

    .event-item__free {
      font-weight: bold;
    }
  }

  &--calendar {
    .event-item__content {
      display: flex;
      gap: 20px;
      color: #fff;
    }

    .event-item__title {
    }

    .event-item__date {
      font-style: italic;
      margin-bottom: 4px;
      font-size: 14px;
    }
  }
}
