.carousel {
  &__container {
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto auto;
    column-gap: 32px;
    border-radius: 10px;
    background: $white;
    max-height: 251px;
    @include tablet {
      grid-template-columns: 1fr;
      grid-template-rows: auto auto auto;
      max-height: inherit;
    }
    &__img {
      grid-row: 1/3;
      grid-column: 1/2;
      @include tablet {
        grid-row: 2/3;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: left;
      }
    }
    &__title {
      font-size: 18px;
      color: $dark;
      max-width: 418px;
      margin-top: 34px;
      margin-bottom: 17px;
      grid-row: 1;
      grid-column: 2/3;
      @include tablet {
        grid-column: 1/2;
        grid-row: 1/2;
        font-size: 13px;
        margin: 20px;
      }
    }
    &__desc {
      font-size: 14px;
      color: $dark;
      width: 90%;
      margin-bottom: 22px;
      grid-row: unset;
      grid-column: 2/3;
      line-height: 25px;
      padding-right: 20px;
      @include tablet {
        grid-column: 1/2;
        grid-row: 3/4;
        font-size: 12px;
        margin: 20px 20px 40px 20px;
      }
    }
  }
  &__controls {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 22px;
    position: static;
    @include tablet {
      margin-top: 18px;
    }
    a {
      display: block;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      border: 1px solid $white;
      background-color: transparent;
      margin-right: 10px;
      transition: 0.3s all;
      &:last-of-type {
        margin-right: 0;
      }
      &.active,
      &:active,
      &:hover {
        background-color: $white;
      }
    }
  }
  &__resource {
    max-width: 502px;
    max-height: 251px;
    img {
      object-fit: fill;
    }
  }
  &__background {
    padding-top: 32px;
    height: 502px;
    background-repeat: no-repeat;
    background-position: bottom center;
  }
  &__blog {
    @extend .carousel__background;
    background-color: $bright-yellow;
    background-image: url('/img/blog-tp.png');
  }
  &__music-activity {
    @extend .carousel__background;
    background-color: $moderate-blue;
    background-image: url('/img/music-activity-tp.png');
  }
  &__creative-ideas {
    @extend .carousel__background;
    background-color: $bush-lilac;
    background-image: url('/img/creative-ideas.png');
  }
  &__music-activity {
    @extend .carousel__background;
    background-color: $moderate-blue;
    background-image: url('/img/music-activity-tp.png');
  }
  &__spotlight {
    @extend .carousel__background;
    background-color: $moderate-green;
    background-image: url('/img/spotlight-tp.png');
  }
  &__music-curriculum-activity {
    @extend .carousel__background;
    background-color: $bush-lilac;
    background-image: url('/img/music-curriculum-activity-tp.png');
  }
  &__guide {
    @extend .carousel__background;
    background-color: $moderate-blue;
    background-image: url('/img/guide-tp.png');
  }
  &__send_resource {
    @extend .carousel__background;
    background-color: $vivid-orange;
    background-image: url('/img/send-resource-tp.png');
  }
  &__curriculum-link {
    @extend .carousel__background;
    background-color: $vivid-orange;
    background-image: url('/img/curriculum-link.png');
  }
  &__bright-idea {
    @extend .carousel__background;
    background-color: #79be41;
    background-image: url('/img/bright-idea.png');
    background-size: 50% 70%;
  }
}
