.esongbook {
	&__container {
		margin-top: 58px;
		@include laptop-sm {
			margin-top: 0px;
			padding: 24px 0px;
		}
		@include tablet {
		}
	}
	&__details {
		&__grid {
			display: grid;
			grid-template-columns: 1.5fr 1fr;
			margin-bottom: 50px;
			@include laptop-sm {
				padding: 0 16px;
			}
			@include mobile {
				grid-template-columns: 1fr;
				row-gap: 30px;
        margin-bottom: 25px;
        padding: 0;
			}
		}
	}
	&__album {
		display: flex;
    align-items: flex-start;
    @include mobile{
      padding: 0 16px;
    }
		&__thumbnail {
			width: 93px;
			height: 93px;
			margin-right: 17px;
		}
		&__title {
			font-size: 20px;
			font-weight: 600;
			max-width: 192px;
			margin-bottom: 0;
			margin-right: 21px;
		}
		&__add {
			font-size: 21px;
			color: $white !important;
		}
	}
	&__resources {
		position: relative;
		&__cta {
			color: $white !important;
			display: flex;
			align-items: center;
			justify-content: space-between;
			height: 48px;
			background-color: $turquoise-medium;
			padding: 15px;
			p {
				font-size: 14px;
				margin-bottom: 0;
			}
			i {
				transition: 0.3s all;
				font-size: 19px;
			}
		}
		&__dropdown {
			background-color: $turquoise-medium;
			padding: 0 16px;
			width: 100%;
			height: 0;
			overflow: hidden;
			position: absolute;
			top: 48px;
			left: 0;
			z-index: 999;
			transition: 0.3s all;
			&__list {
				list-style: none;
				padding: 0;
				margin: 0;
				max-width: 256px;
				li {
					margin-bottom: 8px;
					&:last-of-type {
						margin-bottom: 0;
					}
					a {
						display: flex;
						align-items: flex-start;
						color: $white !important;
					}
					p {
						font-size: 13px;
						line-height: 32px;
						margin-bottom: 0;
					}
					i {
						width: 20px;
						text-align: center;
						margin-right: 22px;
						line-height: 32px;
					}
				}
			}
		}
		&.active {
			.esongbook__resources__cta {
				i {
					transform: rotateZ(180deg);
				}
			}
			.esongbook__resources__dropdown {
				padding: 8px 16px 16px;
				height: auto;
			}
		}
	}
	&__item {
		display: flex;
		align-items: center;
		padding: 10px 0;
		@include laptop-sm {
			padding: 10px 16px;
		}
		&__thumbnail {
			width: 46px;
			height: 46px;
			border-radius: 10px;
			position: relative;
			margin-right: 13px;
			&__bg {
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center;
				border-radius: 10px;
			}
			&__play {
				width: 18px;
				height: 18px;
				padding: 5px;
				border-radius: 50%;
				background-color: $white;
				@include absolute-center;
				@include flex-center;
			}
		}
		&__title {
			font-size: 16px;
			color: $white !important;
			margin-bottom: 0;
			margin-top: 4px;
			@include mobile {
				font-size: 10px;
			}
		}
		&__duration {
			font-size: 16px;
			margin-bottom: 0;
			margin-right: 30px;
			margin-left: auto;
			margin-top: 4px;
			@include mobile {
				font-size: 10px;
				margin-right: 28px;
			}
		}
	}
}
