.songslist {
  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 66px;
    @include laptop-sm {
      background-color: $blue-nile;
      padding: 27px 16px;
    }
    @include tablet {
      margin-bottom: 0;
    }
    &__icon {
      width: 37px;
      color: $grey-chateau;
      margin-right: 26px;
      @include tablet {
        width: 22px;
        margin-right: 17px;
      }
    }
    &__title {
      font-size: 34px;
      font-weight: 400;
      color: $grey-chateau;
      margin-bottom: 0;
      @include tablet {
        font-size: 20px;
      }
    }
    &__filter {
      color: $white !important;
      margin-left: auto;
      &__icon {
        font-size: 24px;
        display: none;
        @include mobile {
          display: block;
        }
      }
      p {
        font-size: 14px;
        margin-bottom: 0;
        @include tablet {
          display: none;
        }
      }
    }
    &__description {
      max-width: 563px;
      font-weight: 400;
      font-size: 18px;
      @include mobile {
        font-size: 16px;
        margin-left: 20px;
        width: 40%;
      }
    }
    &__banner {
      background-color: $moderate-blue;
      margin-top: 0;
      background-repeat: no-repeat;
      background-position: right bottom;
      background-size: 220px 149px;
      display: flex;
      @include mobile {
        padding-bottom: 30px;
        background-image: url('/img/songs-banner-mobile.png');
      }
      &__img {
        margin-top: -100px;
        margin-right: 40px;
        @include mobile {
          display: none;
        }
      }
    }
  }
  &__subheader {
    display: grid;
    grid-template-columns: 2fr 1fr;
    align-items: center;
    padding-bottom: 22px;
    border-bottom: 1px solid $blue-nile;
    margin-bottom: 25px;
    @include laptop-sm {
      padding: 22px 16px;
    }
    &__title {
      font-size: 28px;
      font-weight: 600;
      margin-bottom: 0;
      @include tablet {
        font-size: 20px;
      }
    }
    &__add {
      font-size: 24px;
      color: $white !important;
      margin-left: auto;
    }
  }
  &__container {
    @include laptop-sm {
      padding: 0 16px;
    }
    @include tablet {
      padding: 10px 16px;
    }
  }
  &__item {
    display: flex;
    align-items: center;
    padding: 10px 0;
    &__thumbnail {
      width: 46px;
      height: 46px;
      border-radius: 10px;
      position: relative;
      margin-right: 13px;
      &__bg {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        border-radius: 10px;
      }
      &__play {
        width: 40px;
        height: 40px;
        // padding: 5px;
        border-radius: 50%;
        // background-color: $white;
        @include absolute-center;
        @include flex-center;
      }
    }
    &__title {
      font-size: 14px;
      font-weight: 600;
      color: $white !important;
      margin-bottom: 0;
      margin-top: 4px;
      @include mobile {
        font-size: 10px;
      }
    }
    &__duration {
      font-size: 12px;
      margin-bottom: 0;
      margin-right: 16px;
      margin-left: auto;
      margin-top: 4px;
      @include mobile {
        font-size: 10px;
      }
    }
    &__favorite {
      @include flex-center;
      font-size: 24px;
      color: $cerise-dark !important;
      margin-right: 16px;
      @include tablet {
        color: $white !important;
      }
      .fas {
        width: 0;
        opacity: 0;
        overflow: hidden;
      }
      &.active,
      &:active {
        .fas {
          width: auto;
          opacity: 1;
          @include tablet {
            color: $turquoise-medium !important;
          }
        }
        .far {
          width: 0;
          opacity: 0;
          overflow: hidden;
        }
      }
    }
    &__add {
      @include flex-center;
      font-size: 24px;
      color: $cerise-dark !important;
      @include tablet {
        color: $white !important;
      }
    }
    &__delete {
      @include flex-center;
      font-size: 24px;
      color: $cerise-dark !important;
      @include tablet {
        color: $green-rico !important;
      }
    }
  }
  &__overlay {
    position: absolute;
    top: 0;
  }
  &__search {
    display: flex;
    &__thumbnail {
      width: 88px;
      height: 88px;
      margin-right: 23px;
      position: relative;
      &__bg {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        border-radius: 10px;
      }
      &__play {
        width: 50px;
        height: 50px;
        padding: 17px;
        border-radius: 50%;
        background-color: $white;
        @include absolute-center;
        @include flex-center;
      }
    }
    &__title {
      font-size: 16px;
      color: $moderate-cyan;
      @include mobile {
        font-size: 10px;
      }
    }
  }
}
