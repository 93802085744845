// The order of importing is important, should not be disturbed
@import url('https://use.fontawesome.com/releases/v6.4.0/css/all.css');

// Bootstrap Core
@import '../../node_modules/bootstrap/scss/bootstrap';

@import 'base/variables';
@import 'components/mixins';
@import 'base/page';
@import 'base/type';
@import 'components/container';
@import 'components/margins-padding';
@import 'components/border';
@import 'components/icons';
@import 'components/custom-inputs';
@import 'components/buttons';
@import 'components/body';
@import 'components/sidebar';
@import 'components/main';
@import 'components/grid-custom';
@import 'components/search';
@import 'components/header';
@import 'components/page-header';
@import 'components/song';
@import 'components/songbook';
@import 'components/category';
@import 'components/banner';
@import 'components/resources';
@import 'components/carousels';
@import 'components/songslist';
@import 'components/songpage';
@import 'components/mylist';
@import 'components/resource-page';
@import 'components/filter';
@import 'components/modal';
@import 'components/card';
@import 'components/list';
@import 'components/tags';
@import 'components/audio';
@import 'components/esongbook';
@import 'components/assembly';
@import 'components/sing-subject';
@import 'components/curriculum';
@import 'components/sensory';
@import 'components/schoolroutine';
@import 'components/sing-fun';
@import 'components/choirs';
@import 'components/wellbeing';
@import 'components/sing-community';
@import 'components/collective';
@import 'components/treatbox';
@import 'components/mycollections';
@import 'components/music-curriculum';
@import 'components/curriculum-plan';
@import 'components/song-calendar';
@import 'components/player';
@import 'components/resources-favourites';
@import 'components/manage-account';
@import 'components/nav';
@import 'components/dropdown';
@import 'components/spacing';
@import 'components/link';
@import 'components/notifications';
@import 'components/popup-info';
@import 'components/home-features';
@import 'components/home-events';
@import 'components/home-tips';
@import 'components/simple-card';
@import 'components/quote';
