.grid {
  &__two-items {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 27px;
    row-gap: 27px;
    @include mobile {
      grid-template-columns: 1fr;
      grid-template-rows: auto auto;
      row-gap: 27px;
    }
  }
  &__sm {
    &__items {
      &-two {
        display: grid;
        grid-template-columns: repeat(2, auto);
        grid-row: auto auto;
        row-gap: 16px;
        .first__cell {
          grid-area: 1 / 1 / span 1 / span 1;
        }
        .second__cell {
          grid-area: 1 / 2 / span 1 / span 1;
        }
        .third__cell {
          grid-area: 2 / 1 / span 1 / span 1;
        }
        .fourth__cell {
          grid-area: 2 / 2 / span 1 / span 1;
        }
      }
      &-three {
        display: grid;
        grid-template-columns: repeat(3, auto);
        grid-row: auto auto;
        row-gap: 16px;
        .first__cell {
          grid-area: 1 / 1 / span 1 / span 1;
        }
        .second__cell {
          grid-area: 1 / 2 / span 1 / span 1;
        }
        .third__cell {
          grid-area: 1 / 3 / span 1 / span 1;
        }
        .fourth__cell {
          grid-area: 2 / 1 / span 1 / span 1;
        }
        .fifth__cell {
          grid-area: 2 / 2 / span 1 / span 1;
        }
        .sixth__cell {
          grid-area: 2 / 3 / span 1 / span 1;
        }
      }
    }
  }
  &__vid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 70px;
    @include mobile {
      grid-template-columns: 1fr;
      grid-template-rows: auto auto;
      row-gap: 32px;
    }
    &__thumb {
      position: relative;
      margin-bottom: 20px;
    }
    &__play {
      @include absolute-center;
      @include flex-center;
      @include mobile {
        img {
          width: 55px;
        }
      }
    }
    &__title {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 0;
    }
  }
  &__banner {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 16px;
    @include mobile {
      grid-template-columns: 1fr;
      row-gap: 28px;
    }
  }
}
