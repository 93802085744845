.manage-account {
  &__header {
    display: flex;
    align-items: center;
    @include laptop-sm {
      padding-top: 40px;
      padding-left: 15px;
    }
    &__text {
      @extend .song-calendar__header__text;
      @include mobile {
        font-size: 20px;
      }
    }
  }
  &__segment {
    margin-top: 60px;
    margin-bottom: 47px;
    &__header {
      font-size: 18px;
      margin-bottom: 26px;
      display: block;
      font-weight: 500;
    }
    &__name {
      font-size: 14px;
      margin-left: 20px;
      &__primary {
        font-weight: 500;
        display: block;
        margin-bottom: 3px;
      }
      &__secondary {
        font-weight: 400;
        display: block;
      }
    }
    @include laptop-sm {
      padding-left: 15px;
      padding-right: 15px;
    }
    &__button-edit-account {
      @include mobile {
        margin-top: 2.5rem !important;
        width: 100% important;
      }
    }
    &__button-organization {
      @include mobile {
        margin-top: 2.5rem !important;
        width: 100% important;
      }
    }
  }
  &__card-section {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    &__card {
      border-radius: 10px;
      width: 256px;
      flex-shrink: 0;
      text-align: center;
      font-size: 13px;
      display: inline-block;
      margin-right: 40px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @include tablet {
        width: 240px;
        margin-right: unset;
      }
      @include mobile {
        width: 100%;
        margin-bottom: 28px;
      }
      &__footer {
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        padding: 17px;
        font-size: 14px;
        @include tablet {
          padding-left: 10px;
        }
      }
      &__content {
        padding: 30px 15px;
        @include tablet {
          padding-left: 20px;
        }
      }
      &__orange {
        @extend .manage-account__card-section__card;
        border: 1px solid $orange;
      }
      &__fuchsia {
        @extend .manage-account__card-section__card;
        border: 1px solid $fuchsia;
      }
      &__blue-navy {
        @extend .manage-account__card-section__card;
        border: 1px solid $blue-navy;
      }
      &__cerise-dark {
        @extend .manage-account__card-section__card;
        border: 1px solid $cerise-dark;
      }
      &__cello {
        @extend .manage-account__card-section__card;
        border: 1px solid $cello;
      }
      &__blue-sky {
        @extend .manage-account__card-section__card;
        border: 1px solid $blue-sky;
      }
      &__white {
        @extend .manage-account__card-section__card;
        border: 1px solid $white;
      }
    }
    @include laptop-sm {
      margin-left: 15px;
      margin-right: 15px;
    }
    @include tablet {
      margin-right: 15px;
      justify-content: space-between;
    }
  }
  &__card-section::after {
    @include tablet {
      width: 240px;
      height: 0;
      content: '';
    }
  }
  input,
  select {
    height: 54px;
    padding-left: 20px;
    font-size: 13px;
    color: $black;
    border-radius: 5px !important;
  }
  .form-group {
    margin-bottom: 26px;
  }
  &__avatar {
    border-radius: 50%;
    height: 174px;
    width: 174px;
    object-fit: cover;
    &__md {
      border-radius: 50%;
      height: 64px;
      width: 64px;
      object-fit: cover;
      img {
        border-radius: 50%;
        height: 64px;
        width: 64px;
        object-fit: cover;
      }
      @include mobile {
        height: 84px;
        width: 84px;
      }
    }
    @include tablet {
      display: inline;
    }
  }
  &__invite-colleagues {
    margin-left: -43px;
    margin-right: -43px;
    padding: 43px;
    width: unset;
    color: $white;
    font-size: 12px;

    &__tbody {
      background-color: $body;
      border-top: 1px solid #999;
      border-bottom: 1px solid #999;
      th:first-of-type {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
    th:first-of-type {
      padding-left: 43px;
      // @include mobile {
      //   padding-left: 10px;
      // }
    }
    td {
      padding-top: 10px;
      padding-bottom: 10px;
    }
    @include mobile {
      // padding: unset;
      // margin-left: unset;
      margin-right: -20px;
    }
  }
  &__active {
    color: $green-meadow;
  }
  &__invited {
    color: $orange;
  }
  &__inactive {
    color: #bb2ed4;
  }
  &__subscription-details-row {
    border-bottom: #797979 1px solid;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  &__package-title,
  &__package-price {
    background-color: $fuchsia;
    font-size: 14px;
    padding: 15px 25px;
    display: inline-block;
    border-radius: 10px;
    margin-top: -35px;
    margin-bottom: 40px;
  }

  &__package-price {
    background-color: $blue-navy;
    margin-bottom: -60px;
    margin-top: 10px;
  }
  &__organisation {
    margin-bottom: 40px;
    justify-content: space-between;
    @include laptop-sm {
      padding: 0 15px 30px !important;
    }
  }
}
