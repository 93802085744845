.song-types {
  height: 76px;
  position: fixed;
  bottom: 86px;
  width: 100%;
  background-color: #333;
  padding-left: 30px;
  z-index: 100;
  &__container {
    max-width: 1360px;
    margin: auto;
    padding-top: 17px;
    display: flex;
    justify-content: space-between;
    padding-right: 20px;
  }
  button {
    margin-right: 10px;
    img {
      margin-right: 10px;
    }
  }
  @include mobile {
    display: none;
  }
  @media (max-width: 429px),
    screen and (min-width: 428px) and (max-width: 926px) and (orientation: landscape) {
    display: none;
  }
}
.song-actions {
  grid-column: 6;
  @include mobile {
    display: none;
  }
  @media (max-width: 429px),
    screen and (min-width: 428px) and (max-width: 926px) and (orientation: landscape) {
    display: none;
  }
}
.media-player-bg {
  @include mobile {
    background-color: #293943 !important;
  }
  @media screen and (min-width: 428px) and (max-width: 926px) and (orientation: landscape) {
    background-color: unset !important;
  }
}
.mobile-fullscreen {
  display: none;
  height: 58%;
  background-color: #293943;
  position: fixed;
  bottom: 0px;
  width: 100%;
  padding: 0 21px;
  z-index: 101;
  padding-bottom: 24px;
  @include mobile {
    display: flex;
    flex-direction: column;
  }
  @media (max-width: 429px),
    screen and (min-width: 428px) and (max-width: 926px) and (orientation: landscape) {
    display: flex;
    flex-direction: column;
  }
  .time {
    flex-basis: 35px;
    font-size: 10px;
  }
  .song-resources {
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.35);
    font-family: 'Spartan-Regular', 'Spartan', sans-serif;
    color: #ffffff;
    text-align: center;
    display: inline-flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    height: 50px;
    font-size: 10px;
  }
  .minimize {
    height: 28px;
    width: 28px;
    display: flex;
    align-self: center;
    margin-bottom: 10px;
  }
  .flex-1 {
    flex: 1;
  }
  .flex-2 {
    flex: 2;
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(2rem);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .menu {
    animation: fadeIn 0.4s ease-in-out;
  }
}
.song-controls {
  &__spacer {
    width: 18px;
    @include mobile {
      display: none;
    }
    @media (min-width: 415px) and (max-width: 1024px) {
      width: 4px;
    }
  }
  &__spacer-mobile-fs {
    width: 40px;
  }
}
.test-stuff {
  background-color: black !important;
  color: white;
}
.media-player {
  position: fixed;
  top: 0;
  left: 0;
  &-hide-slides {
    height: 0 !important;
  }
  // @media screen and (min-width: 428px) and (max-width: 926px) and (orientation: landscape) {
  //   transform: rotate(-90deg);
  //   transform-origin: left top;
  //   width: 100vh;
  //   height: 100vw;
  //   overflow-x: hidden;
  //   position: absolute;
  //   top: 100%;
  //   left: 0;
  // }
}
