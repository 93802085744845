.curriculum {
	&__card {
		background-color: $white;
		border-radius: 10px;
		padding: 19px 20px 19px 25px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		@include mobile {
			flex-direction: column;
			align-items: flex-start;
		}
		&__grid {
			display: grid;
			grid-template-columns: 1fr 1fr;
			column-gap: 25px;
			margin-bottom: 55px;
			@include tablet {
				margin-bottom: 38px;
			}
			@include mobile {
				grid-template-columns: 1fr;
				grid-template-rows: auto auto;
				row-gap: 17px;
			}
		}
	}
	&__banner {
		padding: 40px 33px;
		border-radius: 10px;
		position: relative;
    margin-bottom: 50px;
    @include tablet{
      margin-bottom: 43px;
    }
    @include mobile{
      padding: 25px 23px;
    }
		&__heading {
			font-size: 23px;
      margin-bottom: 24px;
      text-transform: uppercase;
			@include mobile {
        font-size: 20px;
        margin-bottom: 17px;
			}
		}
		&__text {
			margin-bottom: 0;
      @include tablet{
        max-width: 500px;
      }
			@include mobile {
				font-size: 12px;
				max-width: 197px;
			}
		}
		&__img {
      position: absolute;
			right: 33px;
			bottom: 0;
			max-height: 135px;
      @include mobile {
        right: 8px;
      }
		}
	}
}
