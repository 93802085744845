.singfun {
	&__edition {
		background-image: url('/img/banana-phone.png');
		background-size: 174px;
		background-position: right 43px bottom;
		@include mobile {
			background-position: right -12px bottom;
		}
		.assembly__header__text {
			margin-bottom: 33px;
			@include mobile {
				margin-bottom: 17px;
				max-width: 240px;
			}
		}
	}
	&__songs {
		&__find {
			grid-template-columns: minmax(auto, 294px) minmax(auto, 230px);
			@include laptop-sm {
				padding-left: 16px;
				padding-right: 16px;
			}
			.assembly__find__range{
				margin-bottom: 0;
			}
			.assembly__find__range__selectors {
				max-width: unset;
			}
			a {
				margin-left: 32px;
			}
			@include mobile {
				grid-template-columns: 1fr;
				a {
					margin-left: 10px;
				}
			}
		}
	}
	&__grid {
		&__playlist {
			display: grid;
			grid-template-columns: repeat(6, 1fr);
			column-gap: 21px;
			@include tablet{
				grid-template-columns: repeat(6, 148px);
			}
			&__thumb {
				display: block;
				margin-bottom: 22px;
				border-radius: 8px;
				img {
					border-radius: 8px;
				}
			}
			&__title {
				font-size: 14px;
        font-weight: 600;
        margin-bottom: 0;
			}
			&__container {
				max-width: calc(100vw - 32px);
				overflow: auto;
				margin-bottom: 44px;
				@include mobile {
					margin-bottom: 53px;
				}
			}
		}
	}
}
