.mycollections {
  &__header {
    padding: 36px 0 23px;
    position: relative;
    display: flex;
    align-items: flex-start;
    @include laptop-sm {
      padding: 36px 16px 23px;
    }
    @include mobile {
      padding: 25px 16px 21px;
      align-items: center;
    }
    &__icon {
      width: 35px;
      margin-right: 27px;
      @include mobile {
        width: 24px;
        margin-right: 13px;
      }
      img {
        width: 100%;
      }
    }
    &__title {
      font-size: 36px;
      font-weight: 400;
      margin-bottom: 0;
      @include mobile {
        font-size: 20px;
      }
    }
  }
  &__container {
    max-width: 699px;
    @include laptop-sm {
      margin: 0 auto;
      margin-top: 23px;
    }
    @include tablet {
      max-width: unset;
    }
  }
  &__create {
    width: 375px;
    @include mobile {
      width: 100vw;
      margin-right: -16px;
    }
  }
}
