.border {
  &__radius {
    &-5 {
      border-radius: 5px;
    }
    &-10 {
      border-radius: 10px;
    }
  }
  &__green {
    border: 1px solid $green-meadow !important;
  }
}
