.searchbar {
  display: flex;
  align-items: center;
  flex-grow: 1;
  height: 36px;
  max-width: 497px;
  padding: 0 10px 0 16px;
  background: $white;
  border-radius: 50px;
  position: relative;
  &__input {
    width: 100%;
    height: 36px;
    padding: 8px;
    background: none;
    border: 0;
    outline: 0;
    font-size: 14px;
    font-weight: 500;
    color: $black;
  }
  &__icon {
    &-search {
      height: auto;
      opacity: 1;
      transition: 0.3s all;
    }
    &-cross {
      height: auto;
      opacity: 1;
      transform: rotate(45deg);
      transition: 0.3s all;
    }
  }
  &.active {
    .searchbar__icon {
      &-search {
        height: 0;
        opacity: 0;
        transition: 0.3s all;
      }
      &-cross {
        height: auto;
        opacity: 1;
        transform: rotate(45deg);
        transition: 0.3s all;
      }
    }
  }
  &__dropdown {
    height: 100%;
    width: 1077px;
    left: 323px;
    top: 76px;
    padding: 40px 44px;
    overflow: hidden;
    background: $cello;
    transition: 250ms all ease-in;
    z-index: 999;
    @include laptop-sm {
      height: 100vh;
      width: 100vw;
      left: unset;
      right: -100vw;
      top: 0;
    }
    &.active {
      height: calc(100vh - 76px);
      max-height: calc(100vh - 76px);
      padding: 40px 44px;
      overflow: auto;
      &::-webkit-scrollbar {
        display: none;
      }
      @include laptop-sm {
        height: 100vh;
        max-height: unset;
        right: 0;
        padding: 16px;
      }
    }
    &__container {
      display: none;
      margin-bottom: 32px;
      @include laptop-sm {
        display: flex;
        align-items: center;
      }
      &__link {
        @include flex-center;
        padding: 10px 16px 10px 0;
        color: $white !important;
      }
      &__input {
        height: 36px;
        width: 100%;
        padding: 8px 16px;
        background: $white;
        border-radius: 50px;
        border: 0;
        outline: 0;
        font-size: 10px;
        font-weight: 500;
        color: $black;
      }
    }
    .heading__section {
      font-size: 18px;
      margin-bottom: 19px !important;
      text-transform: capitalize;
    }
  }
  &__mobile {
    &__input {
      width: 100%;
      height: 36px;
      padding: 0 10px 0 16px;
      background: $white;
      border-radius: 50px;
      font-size: 14px;
      font-weight: 500;
      color: $black;
      margin-bottom: 30px;
    }
    @include laptop-sm {
      display: block !important;
    }
  }
}

.tags {
  &__container {
    margin-top: 10px;
  }

  &__list {
    font-size: 10px;
    padding: 8px;
    background: #778899;
    color: #fff;
    border-radius: 10%;
    margin-right: 5px;

    &:not(:first-child) {
      margin: 0 5px;
    }
  }
}
