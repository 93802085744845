// margins
.mb {
  &-10 {
    margin-bottom: 10px !important;
  }
  &-12 {
    margin-bottom: 12px !important;
  }
  &-13 {
    margin-bottom: 13px !important;
  }
  &-15 {
    margin-bottom: 15px !important;
  }
  &-17 {
    margin-bottom: 17px !important;
  }
  &-18 {
    margin-bottom: 18px !important;
  }
  &-20 {
    margin-bottom: 20px !important;
  }
  &-21 {
    margin-bottom: 21px !important;
  }
  &-22 {
    margin-bottom: 22px !important;
  }
  &-23 {
    margin-bottom: 23px !important;
  }
  &-26 {
    margin-bottom: 26px !important;
  }
  &-28 {
    margin-bottom: 28px !important;
  }
  &-30 {
    margin-bottom: 30px !important;
  }
  &-32 {
    margin-bottom: 32px !important;
  }
  &-40 {
    margin-bottom: 40px !important;
  }
  &-41 {
    margin-bottom: 41px !important;
  }
  &-56 {
    margin-bottom: 56px !important;
  }
  &-64 {
    margin-bottom: 64px !important;
  }
  &-90 {
    margin-bottom: 90px !important;
  }
  &-var {
    margin-bottom: 5% !important;
  }
  @media screen and (min-width: 1201px) {
    &__lg {
      &-10 {
        margin-bottom: 10px !important;
      }
      &-12 {
        margin-bottom: 12px !important;
      }
      &-13 {
        margin-bottom: 13px !important;
      }
      &-14 {
        margin-bottom: 14px !important;
      }
      &-15 {
        margin-bottom: 15px !important;
      }
      &-17 {
        margin-bottom: 17px !important;
      }
      &-20 {
        margin-bottom: 20px !important;
      }
      &-21 {
        margin-bottom: 21px !important;
      }
      &-22 {
        margin-bottom: 22px !important;
      }
      &-23 {
        margin-bottom: 23px !important;
      }
      &-28 {
        margin-bottom: 28px !important;
      }
      &-30 {
        margin-bottom: 30px !important;
      }
      &-32 {
        margin-bottom: 32px !important;
      }
      &-40 {
        margin-bottom: 40px !important;
      }
      &-43 {
        margin-bottom: 43px !important;
      }
      &-56 {
        margin-bottom: 56px !important;
      }
      &-80 {
        margin-bottom: 80px !important;
      }
    }
  }
  @include laptop-sm {
    &__sm {
      &-0 {
        margin-bottom: 0 !important;
      }
      &-10 {
        margin-bottom: 10px !important;
      }
      &-12 {
        margin-bottom: 12px !important;
      }
      &-13 {
        margin-bottom: 13px !important;
      }
      &-15 {
        margin-bottom: 15px !important;
      }
      &-17 {
        margin-bottom: 17px !important;
      }
      &-20 {
        margin-bottom: 20px !important;
      }
      &-21 {
        margin-bottom: 21px !important;
      }
      &-22 {
        margin-bottom: 22px !important;
      }
      &-23 {
        margin-bottom: 23px !important;
      }
      &-26 {
        margin-bottom: 26px !important;
      }
      &-30 {
        margin-bottom: 30px !important;
      }
      &-40 {
        margin-bottom: 40px !important;
      }
      &-43 {
        margin-bottom: 43px !important;
      }
      &-56 {
        margin-bottom: 56px !important;
      }
    }
  }
}
.mt {
  &-19 {
    margin-top: 19px !important;
  }
  &-26 {
    margin-top: 26px;
  }
  &-28 {
    margin-top: 28px;
  }
  &-40 {
    margin-top: 40px !important;
  }
  &-80 {
    margin-top: 80px !important;
  }
  &-190 {
    margin-top: 190px !important;
  }
  &-56 {
    margin-top: 56px !important;
  }
  &-n {
    &84 {
      margin-top: -115px !important;
    }
    &24 {
      margin-top: -24px !important;
    }
    &45 {
      margin-top: -45px;
    }
  }
  @media screen and (min-width: 1201px) {
    &__lg {
      &-40 {
        margin-top: 40px !important;
      }
      &-80 {
        margin-top: 80px !important;
      }
      &-190 {
        margin-top: 190px !important;
      }
    }
  }
  @include tablet {
    &__sm {
      &-2 {
        margin-top: 2px;
      }
    }
  }
  @include mobile {
    &__sm {
      &-0 {
        margin-top: 0 !important;
      }
    }
  }
}
.mr {
  &-12 {
    margin-right: 12px !important;
  }
  &-20 {
    margin-right: 20px !important;
  }
  &-25 {
    margin-right: 25px !important;
  }
  &-30 {
    margin-right: 30px !important;
  }
  @media screen and (min-width: 993px) {
    &__md {
      &-10 {
        margin-right: 10px !important;
      }
      &-12 {
        margin-right: 12px !important;
      }
      &-13 {
        margin-right: 13px !important;
      }
    }
  }
}
.ml {
  @media screen and (min-width: 993px) {
    &-lg {
      &-40 {
        margin-left: 40px !important;
      }
    }
  }
  &-n {
    &-5 {
      margin-left: -5px !important;
    }
  }
  &-24 {
    margin-left: 24px !important;
  }
}
// paddings
.py {
  &-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  &-71 {
    padding-top: 71px !important;
    padding-bottom: 71px !important;
  }
}
.px {
  @include laptop-sm {
    &__sm {
      &-3 {
        padding-left: 16px !important;
        padding-right: 16px !important;
      }
    }
  }
  &-32 {
    padding-left: 32px !important;
    padding-right: 32px !important;
  }
  &-34 {
    padding-left: 34px !important;
    padding-right: 34px !important;
  }
  &-66 {
    padding-left: 66px !important;
    padding-right: 66px !important;
  }
  &-74 {
    padding-left: 74px !important;
    padding-right: 74px !important;
  }
  @media screen and (max-width: 428px) {
    &-sm {
      &-32 {
        padding-left: 32px !important;
        padding-right: 32px !important;
      }
      &-15 {
        padding-left: 15px !important;
        padding-right: 15px !important;
      }
      &-10 {
        padding-left: 10px !important;
        padding-right: 10px !important;
      }
    }
  }
}

.pl {
  @media screen and (min-width: 993px) {
    &-lg {
      &-32 {
        padding-left: 32px !important;
      }
      &-24 {
        padding-left: 24px !important;
      }
    }
  }
  @media screen and (max-width: 414px) {
    &-sm {
      &-16 {
        margin-left: 16px !important;
      }
    }
  }
}

.mx {
  @media screen and (min-width: 993px) {
    &-lg {
      &-n32 {
        margin-left: -32px;
        margin-right: -32px;
      }
      &-n24 {
        margin-left: -24px;
        margin-right: -24px;
      }
    }
  }
  @media screen and (max-width: 414px) {
    &-sm {
      &-n16 {
        margin-left: -16px;
        margin-right: -16px;
      }
    }
  }
}
.p {
  &-25 {
    padding: 25px !important;
  }
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}
