// colors
$white: #fff;
$white-smoke: #f2f2f2;
$dark: #000;
$black-night: #2d2d2d;
$black: #333;
$body: #222f39;
$elephant: #24343e;
$arsenic: #384753;
$cello: #344b59;
$jellybean: #52788c;
$grey: #797979;
$light-grey: #d8d8d8;
$grey-chateau: #9ca3aa;
$grey-light: #d7d7d7;
$blue-nile: #2d424f;
$blue-curious: #3982ba;
$blue-dodger: #0b7dff;
$blue-endeavour: #275f88;
$blue-navy: #0a7bc1;
$blue-sail: #a0c7e6;
$blue-pacific: #009ccf;
$blue-sky: #02a7f0;
$blue-sky-light: #81d3f8;
$turquoise-medium: #40c0c0;
$deep-lilac: #8c5ab3;
$bush-lilac: #9670c7;
$bush-lilac-dark: #855db9;
$amethyst: #9e6ccd;
$cesoir: #93699f;
$orchid: #d166c7;
$fuchsia: #c43bb7;
$cerise: #e83c6f;
$cerise-light: #ff336e;
$cerise-dark: #de2962;
$violet-medium: #d8127d;
$pumpkin: #f27021;
$green-meadow: #15a881;
$green-rico: #59ada2;
$green-jungle: #29ba8a;
$christi: #639f02;
$lime: #65a22f;
$apple: #6fb843;
$mantis: #8dc261;
$conifer: #bde051;
$yellow-tangerine: #fbcb06;
$yellow-sun: #f5bb26;
$mandy: #e15b64;
$flamingo: #e75752;
$orange: #f06d0f;
$dark-desaturated-blue: #223746;
$vivid-orange: #f2860f;
$dark-gray: #9b9b9b;
$light-gray: #ebebeb;
$moderate-cyan: #4dd6d4;
$moderate-blue: #3d82ba;
$moderate-green: #8dc160;
$dark-grayish-blue: #29323b;
$soft-blue: #a1c7e5;
$light-blue: #59adff;
$bright-yellow: #f1cc45;
// screen sizes
$full-hd: 1920px;
$hd: 1440px;
$laptop: 1366px;
$laptop-sm: 1200px;
$tablet: 992px;
$mobile: 767px;
$sm: 600px;
$xsm: 320px;

// font families
$spartan: 'Spartan', sans-serif;
$bravura: Bravura;
$P22MusicPro: P22MusicPro;
