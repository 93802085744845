@charset "UTF-8";
body {
  touch-action: pan-y;
  font-family: $spartan, $bravura;
  color: $white;
  background-color: $body;
}
a {
  text-decoration: none !important;
}
img {
  max-width: 100%;
  height: auto;
}

.sy_cookie_container {
  flex-direction: column;
  align-items: center !important;
  @media (min-width: 768px) {
    flex-direction: row;
  }
}
.sy_cookie_wrapper {
  display: flex;
  flex-direction: column;
}
#rcc-decline-button {
  margin: 0 !important;
}
.sy_cookie_content {
  margin: auto 0px;
  max-height: 100px !important;
  font-size: 14px;
  @media (min-width: 1024px) {
    max-width: 750px;
    max-height: 75px !important;
    max-height: auto !important;
  }
}
// backgrounds helping classes
.bg {
  &__white {
    background-color: $white !important;
  }
  &__cello {
    background-color: $cello !important;
  }
  &__jellybean {
    background-color: $jellybean !important;
  }
  &__green-meadow {
    background-color: $green-meadow !important;
  }
  &__green-jungle {
    background-color: $green-jungle !important;
  }
  &__mantis {
    background-color: $mantis !important;
  }
  &__christi {
    background-color: $christi !important;
  }
  &__lime {
    background-color: $lime !important;
  }
  &__conifer {
    background-color: $conifer !important;
  }
  &__yellow-tangerine {
    background-color: $yellow-tangerine !important;
  }
  &__yellow-sun {
    background-color: $yellow-sun !important;
  }
  &__pumpkin {
    background-color: $pumpkin !important;
  }
  &__cerise {
    background-color: $cerise !important;
  }
  &__cerise-light {
    background-color: $cerise-light !important;
  }
  &__cerise-dark {
    background-color: $cerise-dark !important;
  }
  &__fuchsia {
    background-color: $fuchsia !important;
  }
  &__orchid {
    background-color: $orchid !important;
  }
  &__amethyst {
    background-color: $amethyst !important;
  }
  &__bush-lilac {
    background-color: $bush-lilac !important;
  }
  &__bush-lilac-dark {
    background-color: $bush-lilac-dark !important;
  }
  &__blue-sail {
    background-color: $blue-sail !important;
  }
  &__blue-curious {
    background-color: $blue-curious !important;
  }
  &__blue-pacific {
    background-color: $blue-pacific !important;
  }
  &__blue-nile {
    background-color: $blue-nile !important;
  }
  &__blue-navy {
    background-color: $blue-navy !important;
  }
  &__mandy {
    background-color: $mandy !important;
  }
  &__flamingo {
    background-color: $flamingo !important;
  }
  &__cesoir {
    background-color: $cesoir !important;
  }
  &__apple {
    background-color: $apple !important;
  }
  &__violet-medium {
    background-color: $violet-medium !important;
  }
  &__orange {
    background-color: $orange !important;
  }
  &__vivid-orange {
    background-color: $vivid-orange !important;
  }
  &__dark-desaturated-blue {
    background-color: $dark-desaturated-blue !important;
  }
  &__dark-gray {
    background-color: $dark-gray !important;
  }
  &__moderate-blue {
    background-color: $moderate-blue;
  }
  &__moderate-green {
    background-color: $moderate-green;
  }
  &__moderate-cyan {
    background-color: $moderate-cyan;
  }
  &__arsenic {
    background-color: $arsenic;
  }
  &__dark-grayish-blue {
    background-color: $dark-grayish-blue;
  }
  &__blue-sky {
    background-color: $blue-sky !important;
  }
}

.hr {
  &__white {
    border-color: $white;
  }
  &__lime {
    border-color: $lime;
  }
  &__grey {
    border-color: $grey;
  }
}

// row adjustments
.row {
  &.mobile-adjusted {
    @include mobile {
      margin-left: -5px;
      margin-right: -5px;
    }
    [class*='col-'] {
      @include mobile {
        padding-left: 5px;
        padding-right: 5px;
      }
    }
  }
}
.d {
  &-inherit {
    display: inherit !important;
  }
}
.w {
  &-unset {
    width: unset !important;
  }
  &-sm {
    &-100 {
      @include mobile {
        width: 100% !important;
      }
    }
  }
  &__214 {
    width: 214px !important;
  }
  &__150 {
    width: 150px;
  }
}
.border {
  &-10 {
    border-radius: 10px;
  }
}
// @media screen and (min-width: 428px) and (max-width: 926px) and (orientation: landscape) {
//   body {
//     transform: rotate(-90deg);
//     transform-origin: left top;
//     width: 100vh;
//     height: 100vw;
//     overflow-x: hidden;
//     position: absolute;
//     top: 100%;
//     left: 0;
//   }
// }

@media screen and (min-width: 428px) and (max-width: 926px) and (orientation: landscape) {
  html,
  main {
    margin-left: auto;
    margin-right: auto;
    max-width: 414px;
  }
}

.flip-element {
  @media screen and (min-width: 428px) and (max-width: 926px) and (orientation: landscape) {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh !important;
    height: 100vw !important;
    overflow-x: hidden;
    position: absolute;
    top: 100% !important;
    left: 0;
  }
  &__no-height {
    @extend .flip-element;
    @media screen and (min-width: 428px) and (max-width: 926px) and (orientation: landscape) {
      height: unset !important;
      position: fixed;
    }
  }
}

.time-sig {
  sub {
    position: absolute;
    left: 0;
    top: 2.9ex;
  }
  sup {
    line-height: 1;
  }
}

.section-home {
  .songpage__vid__title {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    padding-top: 20px;
    margin-top: 0 !important;

    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
}
