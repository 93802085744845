.notifications {
  background-color: #0d1317;
  color: $white;
  font-size: 14px;
  &__item {
    padding: 20px;
    width: 464px;
    display: flex;
    @include mobile {
      width: 100%;
    }
    a {
      color: $white;
    }
    &__image {
      width: 67px;
      height: 67px;
      border-radius: 50%;
      margin-right: 15px;
    }
    .resources-favourites__card__thumbnail__show {
      width: 67px;
      height: 67px;
      margin-right: 15px;
      .w-100 {
        img {
          border-radius: 50%;
        }
      }
    }
    &__content {
      display: flex;
      font-size: 14px;
      flex-direction: column;
      justify-content: center;
      flex-wrap: wrap;
      &__header {
        font-weight: bold;
        margin-bottom: 10px;
      }
    }
    &__no-avatar {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 25px;
      background-color: #222;
    }
  }
  &__item:not(:last-child) {
    border-bottom: 1px solid #648292;
  }
  &__unread {
    background-color: #344b59;
  }
  &__unread-indicator {
    background: #ff336e;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    position: absolute;
    top: 8px;
    right: 8px;
  }
  &__tabs {
    margin-bottom: 22px;
    a {
      color: $white;
      padding: 10px;
      font-size: 18px;
      margin-right: 20px;
      transition: all 0.3s linear;
      &:hover {
        text-decoration: none !important;
      }
      &.active {
        border-bottom: 3px $white solid;
      }
    }
  }
}
