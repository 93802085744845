.list {
  &__colored {
    list-style: none;
    padding: 0;
    li {
      font-size: 14px;
      display: flex;
      margin-bottom: 20px;
      @include mobile {
        font-size: 12px;
      }
      &::before {
        content: '';
        width: 9px;
        height: 9px;
        display: inline-block;
        margin-right: 12px;
        margin-top: 5px;
        flex-shrink: 0;
      }
      &:nth-child(4n + 1)::before {
        background: $mantis;
      }
      &:nth-child(4n + 2)::before {
        background: $pumpkin;
      }
      &:nth-child(4n + 3)::before {
        background: $yellow-tangerine;
      }
      &:nth-child(4n + 4)::before {
        background: $blue-sail;
      }
    }
    &__sublist {
      list-style: none;
      li {
        &:nth-child(odd)::before {
          background: $pumpkin;
        }
        &:nth-child(even)::before {
          background: $yellow-tangerine;
        }
      }
    }
    &__ordered {
      list-style: none;
      padding: 0;
      counter-reset: list-colored-counter;
      li {
        counter-increment: list-colored-counter;
        font-size: 14px;
        display: flex;
        margin-bottom: 20px;
        @include mobile {
          font-size: 12px;
        }
        &::before {
          content: counter(list-colored-counter) '.';
          font-weight: 600;
          display: inline-block;
          width: 5px;
          margin-right: 16px;
          word-wrap: normal;
          word-break: normal;
        }
        &:nth-child(4n + 1)::before {
          color: $mantis;
        }
        &:nth-child(4n + 2)::before {
          color: $pumpkin;
        }
        &:nth-child(4n + 3)::before {
          color: $yellow-tangerine;
        }
        &:nth-child(4n + 4)::before {
          color: $blue-sail;
        }
      }
      &__sublist {
        list-style: none;
        counter-reset: sublist-colored-counter;
        li {
          counter-increment: sublist-colored-counter;
          &::before {
            content: counter(sublist-colored-counter, lower-alpha);
            font-weight: 600;
            display: inline-block;
            width: 5px;
            margin-right: 16px;
          }
          &:nth-child(odd)::before {
            color: $pumpkin;
          }
          &:nth-child(even)::before {
            color: $yellow-tangerine;
          }
        }
      }
    }
  }
}
