.resource {
  &__header {
    padding: 28px 0;
    background-color: $cello;
    position: relative;
    @include laptop-sm {
      padding: 25px 16px;
    }
    @include tablet {
      padding: 25px 0;
      min-height: 171px;
    }
    @include sm {
      min-height: 114px;
    }
    &__grid {
      display: grid;
      grid-template-columns: 3fr 1fr;
      align-items: start;
      @include tablet {
        grid-template-columns: 5fr 1fr;
        padding: 0 16px;
      }
    }
    &__info {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
    }
    &__title {
      font-size: 36px;
      font-weight: 500;
      margin-bottom: 8px;
      @include sm {
        font-size: 18px;
        margin-bottom: 4px;
      }
    }
    &__subtitle {
      font-size: 18px;
      margin-bottom: 0;
      @include sm {
        font-size: 12px;
      }
    }
    &__img {
      margin-bottom: 29px;
      @include tablet {
        height: 390px;
      }
      @include sm {
        height: 190px;
        margin-bottom: 48px;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
      }
    }
  }
  &__item {
    &__about {
      position: absolute;
      top: 64px;
      right: 0;
      z-index: 100;
      @include laptop-sm {
        right: 0;
      }
      @include tablet {
        width: 100%;
        top: 511px;
        transform: translateY(25px);
      }
      @include sm {
        top: 83px;
      }
      &__cta {
        @include flex-center;
        justify-content: space-between;
        width: 215px;
        background-color: $turquoise-medium;
        padding: 14px 15px;
        @include tablet {
          width: 100% !important;
          background-color: $turquoise-medium;
        }
        p {
          font-size: 14px;
          margin-bottom: 0;
          margin-right: 16px;
          color: $white !important;
        }
        i {
          color: $white !important;
        }
      }
      &__dropdown {
        background-color: $dark;
        padding: 0 15px;
        height: 0;
        width: 0;
        overflow: hidden;
        @include tablet {
          background-color: $dark;
        }
        &__grid {
          display: grid;
          grid-template-columns: 1fr 1fr;
          column-gap: 20px;
          row-gap: 16px;
        }
      }
      &.active {
        .resource__item__about__cta {
          width: 375px;
          background-color: $dark;
          i {
            transform: rotate(180deg);
          }
          @include tablet {
            width: 100%;
            background-color: $dark;
          }
        }
        .resource__item__about__dropdown {
          width: 375px;
          height: auto;
          padding: 15px;
          @include tablet {
            width: 100%;
          }
        }
      }
    }
  }
  &__container {
    max-width: 701px;
    margin: 0 auto 71px;
    @include laptop-sm {
      margin-top: 40px;
    }
    @include tablet {
      max-width: unset;
      width: 100%;
      margin-top: 0;
      margin-bottom: 32px;
    }
  }
  &__vid {
    &__thumbnail {
      position: relative;
      &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
      &__play {
        @include absolute-center;
        font-size: 41px;
        color: $dark !important;
      }
    }
    &__title {
      padding: 19px 28px;
      background-color: $cello;
      p {
        font-size: 14px;
        margin-bottom: 0;
      }
      @include tablet {
        background-color: $cerise-dark;
        padding: 16px;
      }
    }
  }
  &__effects {
    &__grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 17px;
      @include mobile {
        column-gap: 9px;
      }
      &__item {
        @include flex-center;
        text-align: center;
        height: 147px;
        padding: 16px 50px;
        @include mobile {
          padding: 16px 30px;
        }
      }
    }
  }
  &__song {
    &__details {
      display: flex;
      align-items: flex-start;
    }
    &__thumbnail {
      position: relative;
      border-radius: 10px;
      width: 109px;
      height: 109px;
      margin-right: 22px;
      &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        border-radius: 10px;
      }
      &__play {
        @include absolute-center;
        @include flex-center;
        width: 43px;
        height: 43px;
        border-radius: 50%;
        background-color: $white;
        img {
          width: 19px;
          margin-left: 3px;
        }
      }
    }
    &__info {
      max-width: 214px;
    }
    &__title {
      font-size: 16px;
      font-weight: 600;
      color: $black;
    }
    &__subtext {
      font-size: 12px;
      color: $black;
      margin-bottom: 0;
    }
  }
  // favorite page
  &__favorite {
    &__header {
      margin-bottom: 34px;
      @include laptop-sm {
        background-color: $blue-nile;
        padding: 26px 16px;
        margin-bottom: 28px;
      }
      &__grid {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      &__title {
        font-size: 36px;
        font-weight: 500;
        color: $grey-chateau;
        display: flex;
        align-items: flex-start;
        @include mobile {
          font-size: 20px;
        }
        i {
          margin-right: 31px;
          @include mobile {
            margin-right: 20px;
          }
        }
        h2 {
          font-size: 36px;
          margin-bottom: 0;
          @include mobile {
            font-size: 20px;
          }
        }
      }
      &__filter {
        font-size: 36px;
        color: $white !important;
        @include mobile {
          font-size: 20px;
        }
      }
    }
  }
}
