.mc {
  &__header {
    &__icon {
      @include mobile {
        display: none;
      }
    }
    &__container {
      padding-top: 30px;
      justify-content: space-between;
      display: flex;
      margin-top: 0;
      @include mobile {
        padding-top: 30px;
        padding-bottom: 60px;
      }
    }
    padding: 46px 0 30px;
    text-align: center;
    @include laptop-sm {
      padding: 46px 23px 30px;
    }
    &__title {
      font-size: 36px;
      font-weight: 600;
      text-transform: uppercase;
      margin-bottom: 18px;
      @include mobile {
        font-size: 20px;
        max-width: unset;
      }
    }
    &__toptitle {
      font-size: 34px;
      margin-left: 20px;
      @include mobile {
        font-size: 28px;
      }
    }
    &__text {
      font-size: 19px;
      max-width: 699px;
      margin: 0 auto;
      @include mobile {
        font-size: 12px;
        max-width: unset;
      }
    }
    &__description {
      max-width: 563px;
      font-weight: 400;
      font-size: 18px;
      @include mobile {
        font-size: 16px;
        margin-left: 20px;
        max-width: 50%;
      }
    }
    &__banner {
      @include mobile {
        background-image: url('/img/Category-Banners-MusicCurriculumV2-25.png');
      }
      &__img {
        margin-top: -100px;
        margin-right: 40px;
        @include mobile {
          display: none;
        }
      }
    }
  }
  // tabs
  &__tabs {
    &__year {
      @include flex-center;
      height: 59px;
      font-size: 16px;
      font-weight: 600;
      padding: 4px 19px;
      position: absolute;
      @include tablet {
        position: unset;
        height: unset;
        display: block;
        padding: 0 16px;
        margin-bottom: 27px;
      }
    }
    .nav-tabs {
      border-bottom: 0;
      margin-left: 80px;
      @include laptop-sm {
        padding: 0 16px;
      }
      @include tablet {
        margin-left: 0px;
      }
      .nav-item {
        margin-bottom: 1px;
        margin-right: 6px;
        @include tablet {
          margin-right: 4px;
        }
        &:last-of-type {
          margin-right: 0;
        }
      }
      .nav-link {
        @include flex-center;
        width: 96px;
        height: 59px;
        font-size: 16px;
        font-weight: 600;
        color: $white !important;
        border: 0;
        background-color: $pumpkin;
        border-radius: 5px 5px 0 0;
        @include mobile {
          min-width: 40px;
          max-width: fit-content;
          padding: 8px;
          height: 42px;
          width: unset;
        }
        &.active {
          width: 100px;
          background-color: $white-smoke;
          color: $black !important;
          @include mobile {
            width: unset;
          }
        }
      }
    }
    &__first {
      background-color: $white;
      padding: 20px 16px;
      margin-bottom: 31px;
      border-radius: 0 0 10px 10px;
    }
    // collapsible
    &__collapse {
      margin-bottom: 13px;
      &__header {
        padding: 19px 17px 17px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        background-color: $white-smoke;
        border-radius: 10px;
        transition: 0.3s all;
        cursor: pointer;
        @include mobile {
          padding: 19px 15px 17px;
        }
        p {
          font-size: 15px;
          text-transform: uppercase;
          line-height: 100%;
          color: $black;
          margin-bottom: 0;
          margin-right: 16px;
        }
        a {
          color: $black !important;
          transition: 0.3s all;
        }
      }
      &__body {
        width: 100%;
        height: 0;
        overflow: hidden;
        padding: 0 17px;
        background-color: $white-smoke;
        border-radius: 0 0 10px 10px;
        transition: 0.3s all;
        @include mobile {
          padding: 0 15px;
        }
      }
      &.show {
        .mc__tabs__collapse__header {
          border-radius: 10px 10px 0 0;
          padding: 19px 17px 4px;
          @include mobile {
            padding: 19px 15px 4px;
          }
          a {
            transform: rotateZ(180deg);
          }
        }
        .mc__tabs__collapse__body {
          height: auto;
          padding: 17px 17px;
          @include mobile {
            padding: 17px 15px 2px;
          }
        }
      }
    }
    // grid and cards
    &__grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 16px;
      row-gap: 10px;
      @include mobile {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto;
        row-gap: 17px;
      }
    }
  }
  &__card {
    padding: 22px 17px 20px;
    border-radius: 5px;
    color: $white !important;
  }
}
