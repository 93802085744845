/**
* Default .page-header
*/
.page-header {
  max-width: 1200px;

  &__icon {
    width: 35px;
    margin-right: 16px;

    @include media-breakpoint-up(md) {
      margin-right: 27px;
    }
  }

  &__title-container {
    padding-top: 36px;
    padding-bottom: 36px;
    position: relative;
    display: flex;
    align-items: center;
  }

  &__title {
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 0;

    @include media-breakpoint-up(md) {
      font-size: 36px;
    }
  }

  &__description-bar {
    padding-top: 30px;
    padding-bottom: 30px;
    justify-content: space-between;
    align-items: center;
    display: flex;
    margin-top: 0;
    position: relative;
  }

  &__description {
    font-size: 16px;
    max-width: 50%;

    @include media-breakpoint-up(md) {
      max-width: 50%;
      font-weight: 400;
      font-size: 18px;
    }
  }

  &__banner {
    margin-left: 20px;
    @include media-breakpoint-up(md) {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
}

/**
* Custom .page-header
*/
.page-header--collection {
  .page-header {
    &__title-bar {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include media-breakpoint-up(md) {
        padding-right: 380px;
      }
    }

    &__banner {
      bottom: 20px;
      right: 20px;
    }
  }
}

.page-header--playlists {
  .page-header {
    &__title-bar {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include media-breakpoint-up(md) {
        padding-right: 380px;
      }
    }

    &__description-bar {
      background-color: #3d82ba;
      padding-bottom: 50px;
    }

    &__banner {
      right: 20px;
    }
  }
}
