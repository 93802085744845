.resources-favourites {
  &__header {
    &__icon {
      @include mobile {
        display: none;
      }
      width: 35px;
      height: 32px;
    }
    &__container {
      padding-top: 30px;
      justify-content: space-between;
    }
    &__sub {
      @include mobile {
        flex-direction: column;
      }
    }
    &__text {
      font-size: 34px;
      margin-left: 5px;
      &__muted {
        font-size: 34px;
        margin-left: 5px;
        color: #9b9b9b;
        @include mobile {
          font-size: 28px;
          display: block;
          width: 90%;
        }
      }
      @include mobile {
        font-size: 28px;
        display: block;
        width: 90%;
      }
    }
    &__description {
      max-width: 563px;
      font-weight: bold;
      font-size: 18px;
      @include mobile {
        font-size: 16px;
        margin-left: 20px;
        min-width: 55%;
      }
    }
    &__banner {
      margin-top: -110px;
      margin-right: 50px;
      @include mobile {
        max-height: 149px;
        margin-top: -30px;
        margin-right: -22px;
        transform: translateX(-11px);
      }
    }
    &__filter {
      position: absolute;
      top: 0;
      right: 40px;
      margin-top: -50px;
      @include mobile {
        top: -40px;
        right: 10px;
      }
    }
  }
  &__card {
    background-color: $white;
    border-radius: 10px;
    padding: 22px 28px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 27px;

    @include mobile {
      padding: 16px 13px;
      width: inherit;
    }

    &__thumbnail {
      width: 88px;
      height: 88px;
      margin-right: 23px;
      @include mobile {
        display: none;
      }
    }
    &__thumbnail__show {
      width: 88px;
      height: 88px;
      margin-right: 23px;
      @include mobile {
        display: unset;
        width: 88px;
        height: 88px;
      }
    }

    &__info {
      max-width: 65%;
      color: #000;
    }

    &__actions {
      display: inline-flex;
      align-items: flex-start;
      margin-top: -5px;
      i {
        margin-top: 7px;
      }
      img {
        margin-right: 20px;
        @include mobile {
          margin-right: 10px;
        }
      }
      @include mobile {
        margin-left: 10px;
      }
    }
    a {
      color: $moderate-cyan;
      font-size: 16px;

      @include mobile {
        font-size: 15px;
      }
    }
    p,
    div {
      font-size: 14px;
      color: $arsenic;
      @include mobile {
        font-size: 13px;
      }
    }
    .tag {
      background-color: #b4b4b4;
    }
    .d-flex {
      width: 100%;

      @include mobile {
        width: 80%;
      }
    }
  }
}
