.songpage {
  &__header {
    padding: 28px 0;
    background-color: $cello;
    position: relative;
    @include laptop-sm {
      padding: 25px 16px;
    }
    @include tablet {
      padding: 25px 0;
    }
    &__grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: start;
      @include tablet {
        grid-template-columns: auto auto;
        padding: 0 16px;
      }
    }
    &__info {
      display: flex;
      align-items: flex-start;
    }
    &__thumbnail {
      width: 112px;
      height: 112px;
      position: relative;
      margin-right: 30px;
      flex-shrink: 0;
      @include mobile {
        width: 71px;
        height: 71px;
        margin-right: 19px;
      }
      &__play {
        @include absolute-center;
        @include flex-center;
        background: $white;
        padding: 11px;
        width: 38px;
        height: 38px;
        border-radius: 50%;
        @include mobile {
          width: 24px;
          height: 24px;
          padding: 6px;
        }
      }
    }
    &__title {
      font-size: 38px;
      font-weight: 500;
      margin-bottom: 0;
      @include mobile {
        font-size: 18px;
        margin-bottom: 4px;
      }
    }
    &__subtitle {
      font-size: 18px;
      margin-bottom: 20px;
      @include mobile {
        font-size: 12px;
        margin-bottom: 10px;
      }
    }
    &__subtext {
      font-size: 14px;
      margin-bottom: 0;
      @include mobile {
        font-size: 10px;
      }
    }
  }
  &__item {
    &__about {
      position: absolute;
      top: 64px;
      right: 0px;
      z-index: 99;
      @include laptop-sm {
        right: 0;
      }
      @include tablet {
        width: 100%;
        top: 100%;
        // transform: translateY(25px);
      }
      &__cta {
        @include flex-center;
        justify-content: space-between;
        width: 215px;
        background-color: $turquoise-medium;
        padding: 14px 15px;
        @include tablet {
          width: 100%;
          background-color: $turquoise-medium;
        }
        p {
          font-size: 14px;
          margin-bottom: 0;
          margin-right: 16px;
          color: $white !important;
        }
        i {
          color: $white !important;
        }
      }
      &__dropdown {
        background-color: $dark;
        padding: 0 15px;
        height: 0;
        width: 0;
        overflow: hidden;
        @include tablet {
          background-color: $dark;
        }
        &__grid {
          display: grid;
          grid-template-columns: 1fr 1fr;
          column-gap: 20px;
          row-gap: 16px;
        }
      }
      &.active {
        .songpage__item__about__cta {
          width: 375px;
          background-color: $dark;
          i {
            transform: rotate(180deg);
          }
          @include tablet {
            width: 100%;
            background-color: $dark;
          }
        }
        .songpage__item__about__dropdown {
          width: 375px;
          height: auto;
          padding: 15px;
          @include tablet {
            width: 100%;
          }
        }
      }
    }
  }
  &__container {
    max-width: 701px;
    margin: 0 auto 71px;
    @include laptop-sm {
      margin-top: 40px;
    }
    @include tablet {
      max-width: unset;
      width: 100%;
      margin-top: 49px;
      margin-bottom: 32px;
    }
  }
  &__vid {
    margin-bottom: 29px;
    @include tablet {
      margin-bottom: 29px;
    }
    &__thumbnail {
      position: relative;
      &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
      &__play {
        @include absolute-center;
        font-size: 41px;
        color: $dark !important;
      }
    }
    &__title {
      padding: 19px 28px;
      background-color: $cello;
      p {
        font-size: 14px;
        margin-bottom: 0;
      }
      @include tablet {
        background-color: $black-night;
        padding: 16px;
      }
    }
  }
}
