.card {
  &__plain {
    margin-bottom: 20px;
    @include tablet {
      margin-bottom: 16px;
    }
    &__header {
      padding: 18px 32px;
    }
    &__body {
      padding: 30px 32px;
      a:hover {
        text-decoration: underline !important;
      }
      @include tablet {
        padding: 20px 16px;
      }
      &.plus-padding {
        padding: 40px 32px;
        @include tablet {
          padding: 30px 16px;
        }
      }
      &__banner {
        padding: 22px 32px;
        width: calc(100% + 64px);
        margin-left: -32px;
        @include tablet {
          padding: 19px 16px;
          width: 100%;
          margin-left: unset;
          border-radius: 5px;
        }
      }
    }
    .card__plain__header + .card__plain__body {
      padding: 20px 32px 30px;
      @include tablet {
        padding: 20px 16px;
      }
    }
    &__heading {
      font-size: 18px;
      font-weight: 600;
      line-height: 100%;
      text-transform: uppercase;
      @include tablet {
        font-size: 16px;
      }
    }
    &__subheading {
      font-size: 16px;
      font-weight: 600;
      @include tablet {
        font-size: 14px;
      }
    }
    &-lg {
      padding-bottom: 32px;
      @include mobile {
        margin-bottom: 27px;
      }
      .card__plain__body {
        padding: 38px 23px;
        border-radius: 10px;
        @include mobile {
          padding: 24px 23px;
        }
        p {
          max-width: 496px;
          @include tablet {
            max-width: unset;
          }
        }
      }
      .card__plain__heading {
        font-size: 28px;
        font-weight: normal;
        text-transform: unset;
        margin-bottom: 16px;
        @include tablet {
          font-size: 23px;
        }
      }
    }
  }
  &__favorite {
    margin-bottom: 22px;
    @include mobile {
      margin-bottom: 17px;
    }
    &__body {
      padding: 23px 14px;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      position: relative;
    }
    &__img {
      width: 63px;
      height: 63px;
      margin-right: 17px;
      @include mobile {
        display: none;
      }
    }
    &__info {
      max-width: 256px;
    }
    &__title {
      font-size: 14px;
      font-weight: 600;
      color: $blue-sky;
      margin-bottom: 8px;
      @include mobile {
        font-size: 12px;
      }
    }
    &__subtext {
      font-size: 12px;
      color: $black;
      margin-bottom: 0;
      @include mobile {
        font-size: 10px;
      }
    }
    &__ctas {
      position: absolute;
      right: 11px;
      top: 11px;
      font-size: 21px;
      display: flex;
      align-items: center;
      .item {
        &__favorite {
          @include flex-center;
          font-size: 21px;
          color: $cerise !important;
          margin-right: 9px;
          @include tablet {
            color: $grey-light !important;
          }
          .fas {
            width: 0;
            opacity: 0;
            overflow: hidden;
          }
          &.active,
          &:active {
            .fas {
              width: auto;
              opacity: 1;
              @include tablet {
                color: $turquoise-medium !important;
              }
            }
            .far {
              width: 0;
              opacity: 0;
              overflow: hidden;
            }
          }
        }
        &__add,
        &__remove {
          @include flex-center;
          font-size: 24px;
          color: $cerise !important;
          @include tablet {
            color: $green-meadow !important;
          }
        }
      }
    }
    .tags-wrapper {
      flex-basis: 100%;
      width: 100%;
      max-width: 100%;
      margin-top: 15px;
      margin-left: 81px;
      @include mobile {
        margin-left: unset;
      }
    }
  }
}

.card-nt {
  margin-left: -32px !important;
  margin-right: -32px !important;
  @include mobile {
    margin-left: -16px !important;
    margin-right: -16px !important;
  }
}

.resource-header {
  padding-left: 32px !important;
  padding-right: 32px !important;
  @include mobile {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
}

.resource-card {
  &-img {
    display: inline-block;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  &-content {
    display: inline-block;
    padding: 20px;
  }
  // &-large {
  //   height: 270px;
  // }
}

.album-card {
  img {
    width: 100%;
    height: 100%;
  }
}

.nativities {
  &__thumbnail {
    width: 168px;
    height: 168px;
    position: relative;
    margin-bottom: 32px;
    &__img {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
  }
  &__card {
    width: 201px;
    height: 283.5px;
    border-radius: 10px;
  }
  &__details {
    @include mobile {
      width: 90%;
      max-width: 100%;
    }
  }
  &__play-icon {
    top: 50%;
    left: 50%;
    @include tablet {
      height: 36px;
      width: 36px;
      padding: 8px;
    }
    @include mobile {
      height: 46px;
      width: 46px;
      padding: 13px;
    }
  }
}
