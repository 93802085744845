// media query 
@mixin full-hd{
  @media (min-width: $full-hd) {
    @content;
  }
}
@mixin hd{
  @media (min-width: $hd) {
    @content;
  }
}
@mixin laptop{
  @media (max-width: $laptop) {
    @content;
  }
}
@mixin laptop-sm{
  @media (max-width: $laptop-sm) {
    @content;
  }
}
@mixin tablet{
  @media (max-width: $tablet) {
    @content;
  }
}
@mixin tablet-min{
  @media (min-width: $tablet) {
    @content;
  }
}
@mixin mobile{
  @media (max-width: $mobile), (max-width: 429px),screen and (min-width: 428px) and (max-width: 926px) and (orientation: landscape) {
    @content;
  }
}
@mixin sm{
  @media (max-width: $sm) {
    @content;
  }
}
@mixin xsm{
  @media (max-width: $xsm) {
    @content;
  }
}

// displaying in center with position absolute
@mixin absolute-center{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
// display flex with center position
@mixin flex-center{
  display: flex;
  align-items: center;
  justify-content: center;
}