.nav-tabs-simple {
  flex-wrap: nowrap !important;

  .nav-item:not(:first-child) {
    margin-left: 14px;

    @include mobile {
      margin-left: 8px;
    }
  }
  .nav-link {
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    cursor: pointer;
    padding-top: 6px;
    padding-bottom: 10px;
    text-align: center;
    line-height: 1.4;

    @include mobile {
      font-size: 12px;
      padding-left: 5px;
      padding-right: 5px;
    }

    &:hover {
      text-decoration: none !important;
    }

    &.active {
      border-bottom: 3px solid #fff;
    }
  }
}
