.filter {
  &__dropdown {
    height: 0;
    opacity: 0;
    overflow: hidden;
    width: 375px;
    position: absolute;
    right: 0;
    top: -15px;
    border-radius: 10px;
    background-color: $blue-nile;
    transition: 0.3s all;
    z-index: 999;
    &.active {
      height: auto;
      opacity: 1;
      padding: 25px 15px;
    }
    @include laptop-sm {
      top: 99px;
      right: 0;
    }
    @include tablet {
      top: 80px;
    }
    @include mobile {
      width: 100vw;
      left: 0;
      border-radius: 0;
      top: -15px;
    }
  }
  &__label {
    display: block;
    margin: 0;
    padding: 0;
    font-size: 12px;
    font-weight: 600;
  }
  &__select {
    width: 100%;
    font-size: 11px;
    color: $black;
    padding: 8px 8px 7px 12px;
    background-color: $white;
    outline: 0 !important;
    border: none !important;
    border-radius: 5px;
    option {
      padding: 8px;
      height: 40px;
    }
    &-lg {
      @extend .filter__select;
      font-size: 13px;
      padding: 12px 8px 10px 12px;
      -webkit-appearance: textfield;
      height: 42px;
    }
  }
}

.filter-pills {
  margin-top: 20px;
  overflow-x: auto;
  padding-bottom: 10px;

  &__content{
    display: flex;
    gap: 14px;

    @media (min-width: 640px) {
      gap: 20px;
      flex-wrap: wrap;
    }
  }

  button {
    min-width: 100px;
    height: 48px;
    border-radius: 30px;

    @media (min-width: 640px) {
      min-width: 140px;
      height: 60px;
    }

    background-color: #495f70;

    &.active{
      background-color: #3fc0c0;
    }
  }
}
