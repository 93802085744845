.wellbeing {
  &__edition {
    background-image: url('/img/girl-heart.png');
    background-size: 154px;
    background-position: right bottom;
    .assembly__header__title {
      margin-bottom: 23px;
      @include mobile {
        margin-bottom: 14px;
      }
    }
    .assembly__header__text {
      @include mobile {
        margin-bottom: 20px;
      }
    }
  }
  &__songs {
    &__find {
      grid-template-columns: minmax(auto, 160px) auto minmax(auto, 183px);
      max-width: 662px;
      @include laptop-sm {
        padding-left: 16px;
        padding-right: 16px;
      }
      @include mobile {
        grid-template-columns: 1fr;
      }
      .assembly__find__title {
        grid-column: 1/4;
        margin-bottom: 0;
        @include mobile {
          grid-column: 1/2;
        }
      }
    }
  }
}
