.home-features-carousel {
  .simple-card {
    height: 100%;
    display: flex;
    flex-direction: column;

    a {
      flex: 1;

      .simple-card__content{
        height: 100%;
      }
    }
  }
}

.home-features {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;

  @include tablet {
    grid-template-columns: 1fr 1fr;
  }

  @include mobile {
    grid-template-columns: 1fr;
  }

  &__item {
    flex: 1;

    .simple-card {
      height: 100%;

      &__image {
        padding-top: 100%;
        position: relative;

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      a {
        height: 100%;
        display: flex;
        flex-direction: column;

        .simple-card__content {
          flex: 1;
        }
      }
    }
  }
}
