.sensory{
  &__card {
		background-color: $white;
		border-radius: 10px;
		padding: 25px;
		&__grid {
			display: grid;
			grid-template-columns: 1fr 1fr;
			column-gap: 25px;
			margin-bottom: 51px;
			@include tablet {
				margin-bottom: 56px;
			}
			@include mobile {
				grid-template-columns: 1fr;
				grid-template-rows: auto auto;
				row-gap: 23px;
			}
		}
	}
}