.mylist {
  &__container {
    margin-top: 28px;
	max-width: 698px;
    @include laptop-sm {
      min-height: calc(100vh - 147px);
      padding: 0 16px;
      margin-top: 0;
      background-color: $blue-nile;
    }
    @include tablet {
      min-height: calc(100vh - 129px);
    }
  }
  &__item {
    display: flex;
    align-items: center;
    padding: 10px 0;
    &__thumbnail {
      width: 55px;
      height: 55px;
      border-radius: 10px;
      position: relative;
      margin-right: 19px;
      &__play {
        width: 31px;
        height: 31px;
        @include absolute-center;
        @include flex-center;
      }
    }
    &__title {
      font-size: 12px;
      font-weight: 600;
      color: $white !important;
      margin-bottom: 4px;
      margin-top: 4px;
      display: block;
    }
    &__subtitle {
      font-size: 10px;
      color: $white !important;
      margin-bottom: 0px;
      display: block;
    }
    &__duration {
      font-size: 12px;
      margin-bottom: 0;
      margin-right: 16px;
      margin-left: auto;
      margin-top: 4px;
    }
    &__add {
      @include flex-center;
      margin-left: auto;
      font-size: 24px;
      color: $white !important;
    }
  }
}
