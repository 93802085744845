.choirs{
  &__edition{
    background-image: url('/img/choirs.png');
    background-size: 304px;
    background-position: right bottom;
    @include mobile{
      background-size: 190px;
      background-position: right -48px bottom;
    }
    .assembly__header__text{
      margin-bottom: 35px;
      @include mobile{
        max-width: 280px;
      }
    }
  }
  &__songs{
    &__find{
      max-width: unset; 
      grid-template-columns: minmax(auto, 160px) auto auto auto auto;
      grid-template-rows: auto auto;
      row-gap: 22px;
      @include laptop-sm{
        padding-left: 16px;
        padding-right: 16px;
        column-gap: 20px;
      }
      @include mobile{
        grid-template-columns: 1fr;
      }
      .assembly__find__title{
        grid-column: 1/6;
        margin-bottom: 0;
        @include mobile{
          grid-column: 1/2;
        }
      }
      .assembly__find__range{
        @include mobile{
          margin-bottom: 0;
        }
      }
      .assembly__find__selection{
        @include mobile{
          max-width: calc(100% - 66px);
          &:last-child{
            max-width: unset;
          }
        }
      }
    }
  }
}