.simple-card {
  overflow: hidden;

  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;

  &:not(.simple-card--no-top-radius) {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }

  a{
    &:hover{
      text-decoration: none !important;
    }
  }

  &__content {
    display: block;
    background-color: #fff;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    padding-top: 20px;
  }

  &__img{
    width: 100%;
  }

  &__heading {
    color: #333;
    margin-bottom: 1.5rem;
  }

  &__description {
    color: #333;
    font-size: 1.3rem;
  }
}
