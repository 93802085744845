.singcommunity {
  &__edition {
    background-image: url('/img/sing-in-community.png');
    background-position: right top;
    background-size: contain;
    @include mobile {
      background-position: right -108px top;
      padding: 36px 16px 48px;
      .singsubject__header__title,
      .singsubject__header__text {
        margin-bottom: 26px;
      }
    }
  }
}
.singcommunity {
  &__header {
    padding: 48px 0 51px;
    background: no-repeat url('/img/sing-in-community.png');
    background-position: right bottom;
    background-size: contain;
    @include laptop-sm {
      padding: 48px 16px 51px;
    }
    @include tablet {
      padding: 36px 16px 32px;
    }
    @include mobile {
      padding: 36px 16px 29px;
      background-position: right -50px bottom;
    }
    &__title {
      font-size: 36px;
      font-weight: 600;
      text-transform: uppercase;
      line-height: 1;
      margin-bottom: 23px;
      @include mobile {
        font-size: 20px;
        margin-bottom: 26px;
      }
    }
    &__text {
      font-size: 16px;
      margin-bottom: 37px;
      max-width: 608px;
      @include tablet {
        max-width: 500px;
      }
      @include mobile {
        font-size: 12px;
        max-width: 170px;
        margin-bottom: 26px;
      }
    }
    &__buttons {
      display: grid;
      grid-template-columns: repeat(2, max-content);
      column-gap: 12px;
      @include mobile {
        grid-template-columns: max-content;
        row-gap: 17px;
        column-gap: 0;
      }
    }
  }
}
