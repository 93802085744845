.schoolroutine {
	&__edition {
		background-image: url("/img/students-teacher-holding-register.png");
		background-position: right 25px bottom;
		background-size: 306px;
		@include tablet{
			background-image: url("/img/teacher-holding-register.png");
			background-size: 104px;
		}
	}
}
