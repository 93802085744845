.home-tips {
  position: relative;

  .swiper-slide {
    height: auto;
  }

  &__item {
    height: 100%;

    .simple-card {
      height: 100%;
      a {
        height: 100%;
        display: flex;
        flex-direction: column;

        .simple-card__content {
          flex: 1;
        }
      }
    }
  }

  &__btn {
    position: absolute;
    z-index: 1;
    height: 100%;
    top: 0;
    display: none;

    @media (min-width: 768px) {
      display: block;
    }

    button {
      position: absolute;
      z-index: 2;
      top: 50%;
      transform: translateY(calc(-50% + 3px));
    }

    &-shadow {
      height: 100%;
      width: 100px;
      background: rgb(34, 47, 57);
      background: linear-gradient(
        90deg,
        rgba(34, 47, 57, 0) 0%,
        rgba(34, 47, 57, 1) 100%
      );
    }

    &-prev {
      left: 0;
      width: 100px;

      button {
        left: 10px;
        color: #333;
      }

      .home-tips__btn-shadow {
        background: rgb(34, 47, 57);
        background: linear-gradient(
          90deg,
          rgba(34, 47, 57, 1) 0%,
          rgba(34, 47, 57, 0) 100%
        );
      }
    }

    &-next {
      right: 0;

      button {
        right: 10px;
      }
    }
  }
}
