.banner {
  position: relative;
  padding: 25px 32px;
  border-radius: 10px;
  height: 100%;
  display: block;
  @include mobile {
    height: auto;
    margin-bottom: 26px;
  }
  &__heading {
    color: $white !important;
    font-size: 23px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 17px;
  }
  &__text {
    color: $white !important;
    font-size: 12px;
    margin-bottom: 0;
    max-width: 320px;
    @include tablet {
      max-width: 197px;
    }
  }
  &__bg {
    position: absolute;
    right: 0;
    bottom: 0;
    max-height: 111px;
  }
  &__section {
    margin-bottom: 64px;
    padding: 0;
    @include laptop-sm {
      padding: 0 16px;
    }
    @include mobile {
      margin-bottom: 22px;
    }
  }
  &__quote {
    &__grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      @include mobile {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto;
      }
      &__text {
        padding: 24px 25px;
        p {
          max-width: 236px;
          @include mobile {
            max-width: unset;
          }
        }
      }
    }
  }
  &__big {
    padding: 48px 37px 42px;
    border-radius: 8px;
    @include tablet {
      padding: 34px 25px 30px;
    }
    &__text {
      font-size: 32px;
      font-weight: 600;
      color: $white !important;
      text-transform: uppercase;
      line-height: 40px;
      text-align: center;
      margin: 0;
      @include tablet {
        font-size: 20px;
      }
    }
  }
}
