.resources {
  &__carousel {
    margin-bottom: 64px;
    @include laptop-sm {
      padding: 0 16px;
    }
    @include tablet {
      padding: 28px 23px;
      background-color: $blue-nile;
      margin-bottom: 28px;
    }
  }
}
.see-all-filter {
  grid-template-columns: auto 10% !important;
  @include mobile {
    grid-template-columns: auto auto !important;
  }
}
