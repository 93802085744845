.popup-info {
  position: relative;

  &__action {
    display: inline-block;
    position: relative;

    img {
      height: 44px;
      width: auto;
    }

    > div {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }

  &--header {
    .popup-info__action {
      > div {
        left: 50%;
        right: auto;
        top: 0;
        bottom: auto;
        transform: translateX(-50%);
      }
    }
  }

  &--header {
    .popup-info__action {
      img {
        height: 30px;
      }
    }
  }

  &__modal {
    background-color: #fff;
    color: #333;
    border-radius: 10px;
    padding: 24px;
    z-index: 10;

    -webkit-box-shadow: 10px 10px 5px -8px rgba(0, 0, 0, 0.27);
    -moz-box-shadow: 10px 10px 5px -8px rgba(0, 0, 0, 0.27);
    box-shadow: 10px 10px 5px -8px rgba(0, 0, 0, 0.27);

    font-size: 12px;
    font-weight: 500;
    width: 400px;

    p {
      margin-bottom: 20px;
    }

    h3 {
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 20px;
    }

    &-close {
      width: 14px;
      height: 14px;
      position: absolute;
      right: 14px;
      top: 14px;
      cursor: pointer;
    }
  }
}
