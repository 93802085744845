.assembly {
  &__header {
    padding: 48px 0 32px;
    background: no-repeat url('/img/kids-assembly-lg.png');
    background-position: right bottom;
    background-size: 386px;
    @include laptop-sm {
      padding: 48px 16px 32px;
    }
    @include tablet {
      padding: 36px 16px 32px;
      background-position: right -193px bottom;
    }
    @include mobile {
      padding: 36px 16px 32px;
      background-position: right -216px bottom;
    }
    &__title {
      font-size: 36px;
      font-weight: 600;
      text-transform: uppercase;
      line-height: 1;
      margin-bottom: 23px;
      @include mobile {
        font-size: 20px;
        margin-bottom: 13px;
      }
    }
    &__text {
      font-size: 16px;
      margin-bottom: 45px;
      max-width: 575px;
      @include mobile {
        font-size: 12px;
        max-width: 170px;
        margin-bottom: 13px;
      }
    }
    &__buttons {
      display: grid;
      grid-template-columns: repeat(3, max-content);
      column-gap: 12px;
      @include mobile {
        grid-template-columns: max-content;
        row-gap: 17px;
        column-gap: 0;
      }
    }
    &__plans {
      padding: 44px 0;
      background: no-repeat url('/img/clipboard-lady.png');
      background-size: 190px;
      background-position: right bottom;
      @include laptop-sm {
        padding: 36px 16px;
        background-position: right 16px bottom;
      }
      @include mobile {
        background: no-repeat url('/img/clipboard-lady-full.png');
        background-size: 112px;
        background-position: right 12px bottom 10px;
      }
      &__title {
        font-size: 36px;
        font-weight: 600;
        text-transform: uppercase;
        margin-bottom: 34px;
        line-height: 1;
        @include mobile {
          font-size: 20px;
          margin-bottom: 22px;
        }
      }
      &__text {
        font-size: 16px;
        margin-bottom: 0;
        max-width: 575px;
        @include tablet {
          max-width: 500px;
        }
        @include mobile {
          font-size: 12px;
          max-width: 225px;
        }
      }
    }
    &__allsongs {
      background-color: $blue-nile;
      padding: 32px 0;
      @include laptop-sm {
        margin-bottom: 19px;
      }
      @include tablet {
        padding: 20px 0;
        margin-bottom: 9px;
      }
      .filter__dropdown {
        @include laptop-sm {
          top: 64px;
        }
        @include tablet {
          top: 45px;
        }
        @include mobile {
          background-color: $orchid;
          top: 45px;
        }
      }
    }
    &__banner {
      margin-top: -40px;
      max-height: 185px;
      @include mobile {
        max-height: 169px;
        // margin-top: 60px;
        // margin-right: -22px;
        // transform: translateX(-1px);
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }
    &__description {
      @include mobile {
        min-width: unset !important;
        width: calc(100% - 185px);
        margin-bottom: 20px;
      }
    }
  }
  &__find {
    display: grid;
    grid-template-columns: minmax(auto, 256px) minmax(auto, 160px) auto;
    column-gap: 28px;
    row-gap: 23px;
    align-items: end;
    max-width: 856px;
    padding: 44px 0;
    margin: 0 auto;
    @include tablet {
      padding: 33px 16px 38px;
    }
    @include mobile {
      grid-template-columns: auto;
    }
    &__title {
      font-size: 20px;
      font-weight: 600;
      @include mobile {
        margin-bottom: 0;
      }
    }
    &__range {
      &__selectors {
        display: flex;
        align-items: center;
        @include mobile {
          max-width: calc(100% - 66px);
        }
        p {
          font-size: 14px;
          font-weight: 600;
          flex-grow: 1;
          flex-shrink: 0;
          margin: 0 13px;
          @include mobile {
            margin: 0 26px;
          }
        }
      }
    }
    &__selection {
      @include mobile {
        max-width: calc(100% - 66px);
        &:last-child {
          max-width: unset;
        }
      }
      &__selectors {
        display: flex;
        align-items: center;
        a.button {
          margin-left: 13px;
        }
      }
    }
    label {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 7px;
    }
  }
  &__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 45px;
    row-gap: 55px;
    margin-bottom: 40px;
    @include laptop-sm {
      padding: 0 16px;
    }
    @include tablet {
      column-gap: 18px;
    }
    @include mobile {
      grid-template-columns: 1fr;
      row-gap: 48px;
    }
  }
  &__card {
    display: flex;
    background: $white;
    border-radius: 5px;
    padding: 19px 13px 22px;
    margin-bottom: 18px;
    position: relative;
    min-height: 140px;
    &:last-of-type {
      margin-bottom: 0;
    }
    &__thumbnail {
      display: block;
      width: 65px;
      height: 65px;
      margin-right: 22px;
      flex-shrink: 0;
      @include tablet {
        margin-right: 12px;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: left top;
      }
    }
    &__title {
      font-size: 14px;
      font-weight: 600;
      color: $blue-sky !important;
      margin-bottom: 16px;
      display: block;
      max-width: 300px;
      @include tablet {
        max-width: 190px;
      }
      @include mobile {
        margin-bottom: 8px;
        max-width: 180px;
      }
    }
    &__text {
      font-size: 12px;
      color: $black !important;
      display: block;
      max-width: 287px;
      @include mobile {
        max-width: unset;
      }
    }
    &__cta {
      position: absolute;
      top: 13px;
      right: 13px;
      display: flex;
      align-items: center;
    }
  }
  // for all assemblies page song search
  &__songfinder {
    display: block;
    padding: 33px 0 42px;
    @include laptop-sm {
      padding: 33px 16px 42px;
      margin-bottom: 24px;
    }
    @include tablet {
      padding: 33px 16px 31px;
      max-width: unset;
    }
    &__title {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 25px;
    }
    &__range {
      @include mobile {
        margin-bottom: 5px;
      }
      &__selectors {
        display: flex;
        align-items: center;
        p {
          font-size: 14px;
          font-weight: 600;
          flex-grow: 1;
          flex-shrink: 0;
          margin: 0 13px;
          @include mobile {
            margin: 0 26px;
          }
        }
      }
    }
    &__value {
      @include mobile {
        margin-bottom: 5px;
      }
    }
    &__selection {
      &__selectors {
        display: flex;
        align-items: center;
        @include mobile {
          margin-bottom: 5px;
        }
        a.button {
          margin-left: 18px;
          @include mobile {
            margin-left: 10px;
          }
        }
      }
    }
    label {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 7px;
    }
  }
  &__filter {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto;
    column-gap: 15px;
    row-gap: 22px;
    align-items: end;
    @include mobile {
      grid-template-columns: auto;
    }
    &__choir {
      grid-template-columns: auto auto auto !important;
      @include mobile {
        grid-template-columns: auto !important;
      }
    }
  }
  &__grid {
    &__playlist {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      column-gap: 22px;
      @include tablet {
        grid-template-columns: repeat(6, 118px);
        column-gap: 18px;
      }
      &__container {
        max-width: calc(100vw - 32px);
        overflow: auto;
        margin-bottom: 57px;
        @include laptop-sm {
          padding: 0 10px;
        }
      }
      &__item {
        display: flex;
        flex-direction: column;
        &__thumb {
          border-radius: 5px;
          margin-bottom: 15px;
          @include mobile {
            margin-bottom: 19px;
          }
          img {
            width: 100%;
            height: 100%;
            border-radius: 5px;
            object-fit: cover;
            object-position: center;
            max-width: 142.333px;
            max-height: 141.083px;
          }
        }
        &__title {
          font-size: 14px;
          font-weight: 500;
          color: $white !important;
          margin-bottom: 0;
          @include mobile {
            font-size: 10px;
          }
        }
      }
    }
    &__casestudy {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 27px;
      &__item {
        display: grid;
        grid-template-columns: auto auto;
        column-gap: 32px;
        @include mobile {
          grid-template-columns: 1fr;
          row-gap: 24px;
          column-gap: 0;
        }
      }
      &__info {
        font-size: 16px;
        @include mobile {
          font-size: 12px;
        }
      }
    }
  }
  // banner
  &__banner {
    padding: 30px 38px;
    background: no-repeat url('/img/clipboard-lady-lg.png');
    background-position: right 31px bottom;
    background-size: 299px;
    margin-bottom: 58px;
    @include tablet {
      padding: 26px 16px;
      margin-bottom: 31px;
      background-size: 250px;
    }
    @include mobile {
      background: no-repeat url('/img/clipboard-lady-lg-full.png');
      background-position: right 18px top 19px;
      background-size: 104px;
    }
    &__title {
      font-size: 28px;
      font-weight: 600;
      text-transform: uppercase;
      margin-bottom: 20px;
      @include mobile {
        font-size: 20px;
        margin-bottom: 16px;
      }
    }
    &__text {
      font-size: 16px;
      margin-bottom: 30px;
      max-width: 575px;
      @include mobile {
        font-size: 12px;
        max-width: 215px;
      }
    }
    &__buttons {
      display: flex;
      flex-wrap: wrap;
      max-width: 478px;
      @include mobile {
        max-width: unset;
      }
      a {
        margin-right: 12px;
        margin-bottom: 13px;
        &:last-of-type {
          margin-bottom: 21px;
        }
      }
    }
  }
  &__casestudies {
    padding: 32px 0;
    margin-bottom: 37px;
    @include laptop-sm {
      padding: 32px 16px;
      background-color: $elephant;
    }
  }
  &__song {
    &__header {
      padding: 38px 0 20px;
      @include laptop-sm {
        padding: 38px 16px 20px;
      }
      @include mobile {
        padding: 16px;
      }
      &__links {
        margin-bottom: 26px;
        @include mobile {
          margin-bottom: 15px;
        }
        a {
          font-size: 14px;
          color: $white !important;
          &.active {
            font-weight: 600;
          }
        }
      }
      &__info {
        display: flex;
        align-items: center;
      }
      &__thumb {
        width: 58px;
        height: 58px;
        margin-right: 23px;
        @include mobile {
          display: none;
        }
      }
      &__title {
        font-size: 36px;
        font-weight: 600;
        margin-bottom: 0;
        @include mobile {
          font-size: 18px;
        }
      }
    }
    &__container {
      max-width: 698px;
      margin: 0 auto;
      @include laptop-sm {
        margin-top: 40px;
      }
      @include tablet {
        max-width: unset;
      }
      @include mobile {
        margin-top: 21px;
      }
    }
    &__description {
      font-size: 16px;
      margin-bottom: 32px;
      @include mobile {
        font-size: 11px;
        margin-bottom: 20px;
        padding: 0 16px;
      }
    }
    &__vid {
      margin-bottom: 40px;
      @include mobile {
        padding: 0 16px;
        margin-bottom: 24px;
      }
      &__thumb {
        position: relative;
      }
      &__play {
        @include absolute-center;
        font-size: 41px;
        color: $white;
      }
      &__title {
        background-color: $cello;
        padding: 23px 28px 18px;
        @include mobile {
          padding: 16px 14px;
        }
        p {
          margin-bottom: 0;
          font-size: 14px;
          @include mobile {
            font-size: 12px;
          }
        }
      }
    }
    &__choice {
      display: grid;
      grid-template-columns: 1fr;
      row-gap: 16px;
      margin-bottom: 28px;
      @include mobile {
        grid-template-columns: 46px auto;
        grid-template-rows: auto;
        column-gap: 13px;
        align-items: center;
        margin-bottom: 15px;
      }
      &__thumbnail {
        width: 129px;
        height: 129px;
        border-radius: 10px;
        position: relative;
        @include mobile {
          width: 46px;
          height: 46px;
        }
      }
      &__img {
        border-radius: 10px;
      }
      &__icon {
        @include flex-center;
        @include absolute-center;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: $white;
        @include mobile {
          width: 26px;
          height: 26px;
        }
        img {
          margin-left: 5px;
          @include mobile {
            width: 10px;
            margin-left: 2px;
          }
        }
      }
      &__title {
        font-size: 14px;
        font-weight: 600;
        line-height: 120%;
        margin-bottom: 0;
        @include mobile {
          font-size: 10px;
        }
      }
    }
    // tabs
    &__tabs {
      .nav-tabs {
        border-bottom: 0;
        @include laptop-sm {
          padding: 0 16px;
        }
        .nav-item {
          margin-bottom: 1px;
          margin-right: 6px;
          &:last-of-type {
            margin-right: 0;
          }
        }
        .nav-link {
          @include flex-center;
          width: 70px;
          height: 59px;
          font-size: 16px;
          font-weight: 600;
          color: $white !important;
          border: 0;
          background-color: $cello;
          border-radius: 5px 5px 0 0;
          @include mobile {
            width: 62px;
            height: 53px;
          }
          @media (max-width: 374px) {
            width: 52px;
          }
          &.active {
            width: 78px;
            background-color: $white-smoke;
            color: $black !important;
            @include mobile {
              width: 70px;
            }
            @media (max-width: 374px) {
              width: 52px;
            }
          }
        }
      }
      &__first {
        background-color: $white-smoke;
        padding: 28px 32px;
        margin-bottom: 13px;
        @include mobile {
          padding: 39px 16px;
        }
      }
    }
    &__card {
      display: grid;
      grid-template-columns: auto 1fr;
      column-gap: 30px;
      align-items: center;
      @include mobile {
        column-gap: 18px;
      }
      &__thumbnail {
        width: 129px;
        height: auto;
        border-radius: 10px;
        position: relative;
        @include mobile {
          width: 46px;
          height: 46px;
        }
      }
      &__img {
        border-radius: 10px;
      }
      &__icon {
        @include flex-center;
        @include absolute-center;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: $white;
        @include mobile {
          width: 18px;
          height: 18px;
        }
        img {
          margin-left: 5px;
          @include mobile {
            width: 10px;
            margin-left: 2px;
          }
        }
      }
      &__title {
        color: $dark;
        font-weight: 600;
        line-height: 120%;
        margin-bottom: 0;
        @include mobile {
          font-size: 10px;
        }
      }
    }
  }
}
.theme-page {
  &:last-of-type {
    margin-bottom: 18px !important;
  }
  &__grid {
    row-gap: 0 !important;
  }
  &__desc {
    max-width: 417px !important;
  }
  &__card {
    padding: 26px 17px 30px 28px;
    border-radius: 10px;
    margin-bottom: 23px;
  }
}
.assembly-desc {
  text-transform: inherit;
  line-height: 1.6;
}
