.dropdown-sorting {
  font-size: 14px;
  font-weight: 500;

  .btn {
    font-size: 14px;
  }

  .dropdown-item {
    font-size: 14px;
  }
}
