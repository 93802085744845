.modal {
  &-content {
    background-color: transparent;
    border: none;
    border-radius: 0;
  }
  overflow: scroll !important;
  &__bg-dark {
    background-color: rgba(0, 0, 0, 0.5);
  }
}
.custom {
  &__modal {
    &__filter {
      width: 375px;
      @include mobile {
        margin: auto;
      }
    }
    &__filter-new {
      width: 375px;
      @include mobile {
        margin: auto;
      }
    }
    &__content {
      padding: 28px 24px;
      background-color: $white;
      @include mobile {
        min-height: 100vh;
      }
    }
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 23px;
    }
    &__title {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 0;
      color: $black;
    }
    &__close {
      cursor: pointer;
      transform: rotate(45deg);
    }
    &__songbook-series {
      max-width: 637px;
      &__content {
        border-radius: 10px;
      }
      @include mobile {
        width: 100%;
        margin: 0;
      }
    }
    &__edit-account,
    &__leave-organization,
    &__invite-colleagues {
      max-width: 939px;
      &__content {
        border-radius: 10px;
        background-color: $cello;
        min-height: 772px;
        margin: auto auto;
        padding: 43px;
        color: $white;
        &__title {
          font-size: 18px;
          color: $white;
          @include mobile {
            font-size: 14px;
          }
        }
        .avatar {
          @include mobile {
            height: 134px;
            width: 134px;
            display: inline-block;
            margin-bottom: 10px;
          }
        }
        @include mobile {
          padding: 20px;
          min-height: 100vh !important;
        }
      }
      @include mobile {
        width: 100%;
        margin: 0;
      }
    }
    &__leave-organization {
      max-width: 628px;
      &__content {
        @extend .custom__modal__edit-account__content;
        min-height: 655px;
        @include tablet {
          max-width: 95%;
        }
        @include mobile {
          max-width: 100%;
        }
      }
      &__notice {
        font-size: 14px;
        line-height: 22px;
        text-align: center;
      }
    }
    &__onboarding-questions {
      max-width: 555px;
      @include mobile {
        width: 100%;
      }
    }
  }
}
