.header {
  margin-bottom: 45px;
  @include tablet {
    margin-bottom: 24px;
  }
  &__carousel {
    position: relative;
    &__item {
      width: 100%;
      border-radius: 10px;
      position: relative;
      @include laptop-sm {
        border-radius: 0;
      }
    }
    &__controls {
      position: absolute;
      right: 24px;
      bottom: 14px;
      margin-right: inherit;
      left: inherit;
      a {
        display: inline-block;
        width: 9px;
        height: 9px;
        border-radius: 100%;
        border: 1px solid $white;
        background-color: transparent;
        margin-right: 7px;
        transition: 0.3s all;
        &:last-of-type {
          margin-right: 0;
        }
        &:active,
        &.active,
        &:hover {
          background-color: $white;
        }
      }
    }
    &__bg {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
      border-radius: 10px;
      @include laptop-sm {
        border-radius: 0;
        min-height: 280px;
      }
      @include tablet {
        object-fit: contain;
        object-position: top;
        min-height: unset;
      }
    }
    &__play {
      position: absolute;
      z-index: 99;
      @include absolute-center;
    }
  }
}
