.icon {
  &__favorite {
    @include flex-center;
    font-size: 24px;
    color: $white !important;
    &.red {
      color: $cerise-dark !important;
    }
    .fas {
      width: 0;
      opacity: 0;
      overflow: hidden;
    }
    &.active,
    &:active {
      .fas {
        width: auto;
        opacity: 1;
        color: $turquoise-medium !important;
      }
      .far {
        width: 0;
        opacity: 0;
        overflow: hidden;
      }
    }
    &.red {
      &.active,
      &:active {
        .fas {
          color: $cerise-dark !important;
        }
      }
    }
  }
  &__add {
    @include flex-center;
    font-size: 24px;
    color: $white !important;
    &.red {
      color: $cerise-dark !important;
    }
  }
  &__box {
    width: 9px;
    height: 9px;
    display: inline-block;
    margin-top: 3px;
  }
}
