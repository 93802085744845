.collective {
  &__edition {
    background-image: url(/img/collective-worship.png);
    background-position: right bottom;
    background-size: 388px;
    @include mobile {
      background-size: 324px;
      background-position: right -165px bottom;
    }
    .assembly__header__title {
      @include mobile {
        margin-bottom: 16px;
      }
    }
    .assembly__header__text {
      @include mobile {
        margin-bottom: 21px;
      }
    }
  }
}
.collection {
  &__card-album-art {
    width: 102px;
    height: 102px;
    display: inline-flex;
    margin-right: 15px;
  }
  &__featured-album-art {
    display: inline-flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    img {
      width: 50%;
      height: 50%;
      border-radius: 0;
      max-width: 70px;
      max-height: 70px;
    }
  }
}
