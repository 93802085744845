body {
  display: flex;
  line-height: 1.6;
}
#root {
  display: block;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: column;
}
.pmusicpro {
  font-family: P22MusicPro !important;
  font-size: 30px;
  display: inline;
}
.pagnifin {
  font-family: Pagnifin, Bravura !important;
  display: inline;
}
.bravura {
  font-family: Bravura !important;
  font-size: 25px;
  display: inline;
}
.z-100 {
  z-index: 100;
}
.tooltip-inner {
  font-size: 11px;
  background-color: #e5e5e5;
  font-style: italic;
  font-family: Verdana, sans-serif, Bravura;
  color: #000;
  padding: 15px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  &__text {
    color: #505050;
    text-decoration: underline;
    text-decoration-style: dotted;
  }
  &__header {
    background-color: #40c0c0;
    color: #fff;
    margin: -15px -15px 10px -15px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-style: normal;
    font-weight: bold;
    font-family: Spartan;
  }
  ul {
    list-style-type: disc !important;
    text-align: left;
    padding-left: 15px;
  }
  p {
    border-bottom: 1px solid #000;
    padding-bottom: 15px;
    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }
  }
}

.tooltip {
  .arrow {
    display: none;
  }
}

.tooltip.show {
  opacity: 1;
}

.h {
  &-m {
    &-73 {
      @media (max-width: 415px) {
        height: 73px !important;
      }
    }
  }
}

.nav {
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}
.header-nav {
  background-color: $body;
  z-index: 1040;
}

a {
  &:hover {
    text-decoration: underline !important;
  }
}
.cursor-pointer {
  cursor: pointer;
}
.disable-element {
  pointer-events: none;
  opacity: 0.4;
}
p {
  word-wrap: anywhere;
  word-break: break-word;
}
.normal-text {
  font-style: normal !important;
}
.table-responsive {
  display: block !important;
}
.new-toast-style {
  background-color: #f00;
}
.toast {
  &__container {
    background-color: $cello !important;
    color: $white !important;
  }
}
.user-avatar-organisations {
  border: 2px solid $turquoise-medium;
}
.card-section-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include laptop-sm {
    padding: 0 10px;
  }
}