.category {
  margin-bottom: 72px;
  @include tablet {
    margin-bottom: 54px;
  }
  .col-6 {
    &:nth-child(odd) {
      @include tablet {
        padding-right: 8px;
      }
    }
    &:nth-child(even) {
      @include tablet {
        padding-left: 8px;
      }
    }
  }
  &__thumbnail {
    display: block;
    position: relative;
    border-radius: 10px;
    margin-bottom: 28px;
    @include tablet {
      margin-bottom: 14px;
    }
    &__heading {
      color: $white !important;
      font-size: 28px;
      font-weight: 600;
      text-transform: uppercase;
      text-align: center;
      position: absolute;
      @include mobile {
        font-size: 14px;
      }
    }
    &__img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      border-radius: 10px;
    }
    &.one {
      .category__thumbnail__heading {
        top: 10%;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    &.two {
      .category__thumbnail__heading {
        top: 20%;
        left: 5%;
      }
    }
    &.three {
      .category__thumbnail__heading {
        top: 10%;
        left: 8%;
      }
    }
    &.four {
      .category__thumbnail__heading {
        top: 20%;
        left: 9%;
      }
    }
    &.five {
      .category__thumbnail__heading {
        top: 7%;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    &.six {
      .category__thumbnail__heading {
        bottom: 30%;
        left: 4%;
      }
    }
    &.seven {
      margin-bottom: 0;
      .category__thumbnail__heading {
        top: 10%;
        left: 12%;
      }
    }
    &.eight {
      margin-bottom: 0;
      .category__thumbnail__heading {
        top: 6%;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}

.home-categories {
  position: relative;

  &__item {
    padding-top: 20px;
    position: relative;

    &-inner {
      display: block;

      border-radius: 10px;
      display: flex;
      align-items: center;
      padding-left: 10px;
      padding-right: 10px;
      text-align: right;

      a {
        &:hover {
          text-decoration: none !important;
        }

        display: flex;
        align-items: flex-end;
        height: 42px;
        gap: 10px;
      }

      h3 {
        height: 42px;
        font-size: 12px;
        font-weight: bold;
        color: #fff;
        text-transform: uppercase;
        margin-bottom: 0;
        display: flex;
        align-items: center;
      }

      img {
        height: 50px;
        width: auto;
      }
    }
  }

  &__btn {
    position: absolute;
    bottom: 0;
    z-index: 1;
    height: 100%;

    button {
      position: absolute;
      z-index: 2;
      bottom: 7px;
    }

    &-shadow {
      height: 100%;

      svg {
        height: 100%;
      }
    }

    &-prev {
      left: 0;

      button {
        left: 10px;
        color: #333;
      }

      .home-categories__btn-shadow {
        transform: rotate(180deg);
      }
    }

    &-next {
      right: 0;

      button {
        right: 10px;
      }
    }
  }
}
