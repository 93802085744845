/*--- Custom Checkbox Mark Start---*/
.checkbox {
  &__white {
    [type='checkbox']:checked,
    [type='checkbox']:not(:checked) {
      position: absolute;
      left: -9999px;
    }

    [type='checkbox']:checked + label,
    [type='checkbox']:not(:checked) + label {
      display: inline-block;
      font-size: 13px;
      color: $black;
      padding-left: 38px;
      margin-bottom: 0;
      position: relative;
      cursor: pointer;
      user-select: none;
    }

    [type='checkbox']:checked + label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 16px;
      height: 16px;
      border: 1px solid $grey;
      background: $white;
    }
    [type='checkbox']:not(:checked) + label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 16px;
      height: 16px;
      border: 1px solid $grey;
      background: $white;
    }

    [type='checkbox']:checked + label:after,
    [type='checkbox']:not(:checked) + label:after {
      content: '';
      position: absolute;
      top: 2px;
      left: 5px;
      transition: all 0.2s ease;
      display: block;
      width: 5px;
      height: 10px;
      border: solid $grey;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }

    [type='checkbox']:not(:checked) + label:after {
      opacity: 0;
    }

    [type='checkbox']:checked + label:after {
      opacity: 1;
    }
  }
  &__turquoise-medium {
    [type='checkbox']:checked + label,
    [type='checkbox']:not(:checked) + label {
      padding-left: 34px;
    }
    [type='checkbox']:checked + label:before {
      width: 20px;
      height: 20px;
      border: 2px solid $turquoise-medium;
    }
    [type='checkbox']:not(:checked) + label:before {
      width: 20px;
      height: 20px;
      border: 2px solid $turquoise-medium;
    }
    [type='checkbox']:not(:checked):disabled + label:before {
      border: 2px solid #aaa;
    }

    [type='checkbox']:checked + label:after,
    [type='checkbox']:not(:checked) + label:after {
      border: solid $turquoise-medium;
      content: '';
      position: absolute;
      top: 3px;
      left: 7px;
      transition: all 0.2s ease;
      display: block;
      width: 5px;
      height: 10px;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }
}
/*--- Custom Checkbox Mark End---*/
.input {
  &__control {
    input,
    textarea {
      width: 100%;
      height: 35px;
      padding: 8px;
      font-size: 12px;
      color: $grey-light;
      background-color: $white;
      border: 1px solid $grey-light;
      border-radius: 5px;
    }
    textarea {
      height: auto;
      resize: none;
    }
  }
  &__age-range {
    height: 38px;
  }
}

.share {
  &__input {
    border-color: #4bb691;
    color: #333 !important;
    font-size: 11px !important;
  }
  &__copy-icon {
    font-size: 24px;
    color: #4bb691;
  }
}


  input[type='date'] {
    background: none;
    color: $white;
    outline: none;
    &:invalid {
      border: 1px solid red;
    }
    background-image: url('/img/calendar-icon-green.svg');
    background-repeat: no-repeat;
    background-position: right;
    padding-right: 30px;
  }
