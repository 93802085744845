// Google font Spartan
@import url('https://fonts.googleapis.com/css2?family=Spartan:wght@300;400;500;600;700&display=swap');

//@import url('//hello.myfonts.net/count/3d7fc7');

@font-face {
  font-family: P22MusicPro;
  src: url('/fonts/webFonts/P22MusicPro/font.woff2') format('woff2'),
    url('/fonts/webFonts/P22MusicPro/font.woff') format('woff');
}

@font-face {
  font-family: Bravura;
  src: url('/fonts/Bravura.otf');
}

@font-face {
  font-family: Pagnifin;
  src: url('/fonts/pagnifin-webfont.woff2');
}

.text {
  // font weights
  &__fw {
    &-200 {
      font-weight: 200 !important;
    }
    &-400 {
      font-weight: 400 !important;
    }
    &-500 {
      font-weight: 500 !important;
    }
    &-600 {
      font-weight: 600 !important;
    }
    &-650 {
      font-weight: 650 !important;
    }
    &-700 {
      font-weight: 700 !important;
    }
  }
  // font size
  &__fs {
    &-10 {
      font-size: 10px !important;
    }
    &-11 {
      font-size: 11px !important;
    }
    &-12 {
      font-size: 12px !important;
    }
    &-13 {
      font-size: 13px !important;
    }
    &-14 {
      font-size: 14px !important;
    }
    &-15 {
      font-size: 15px !important;
    }
    &-16 {
      font-size: 16px !important;
    }
    &-18 {
      font-size: 18px !important;
    }
    &-19 {
      font-size: 19px !important;
    }
    &-20 {
      font-size: 20px !important;
    }
    &-21 {
      font-size: 21px !important;
    }
    &-22 {
      font-size: 22px !important;
    }
    &-24 {
      font-size: 24px !important;
    }
    &-25 {
      font-size: 25px !important;
    }
    &-28 {
      font-size: 28px !important;
    }
    &-34 {
      font-size: 34px !important;
    }
    &-m {
      &-8 {
        @media (max-width: 428px) {
          font-size: 8px !important;
        }
      }
    }
  }
  &__md {
    font-size: 14px;
    @include mobile {
      font-size: 13px;
    }
  }
  &__sm {
    font-size: 13px;
    @include mobile {
      font-size: 13px;
    }
  }
  // font family
  &__ff {
    &-spartan {
      font-family: $spartan;
    }
  }

  //alignment
  &__center {
    text-align: center !important;
  }

  // colors
  &__dark {
    color: $dark !important;
  }
  &__black {
    color: $black !important;
  }
  &__light-grey {
    color: $light-grey !important;
  }
  &__black-night {
    color: $black-night !important;
  }
  &__white {
    color: $white !important;
  }
  &__grey {
    color: $grey !important;
  }
  &__grey-chateau {
    color: $grey-chateau !important;
  }
  &__blue-curious {
    color: $blue-curious !important;
  }
  &__blue-dodger {
    color: $blue-dodger !important;
  }
  &__turquoise-medium {
    color: $turquoise-medium !important;
  }
  &__elephant {
    color: $elephant !important;
  }
  &__blue-sky {
    color: $blue-sky !important;
  }
  &__blue-sky-light {
    color: $blue-sky-light !important;
  }
  &__orange {
    color: $orange !important;
  }
  &__moderate-green {
    color: $moderate-green;
  }
  &__pumpkin {
    color: $pumpkin;
  }
  &__cerise-light {
    color: $cerise-light;
  }

  // style
  &__underline {
    text-decoration: underline !important;
  }
  &__underline-hover {
    &:hover {
      text-decoration: underline !important;
    }
  }
  &__no-underline-hover {
    &:hover {
      text-decoration: none !important;
    }
  }
}
.lh {
  &-100 {
    line-height: 100% !important;
  }
  &-120 {
    line-height: 120% !important;
  }
  &-140 {
    line-height: 140% !important;
  }
  &-26 {
    line-height: 26px !important;
  }
}
// headings
.heading {
  &__section {
    font-size: 18px;
    margin-bottom: 0;
    text-transform: uppercase;
    @include mobile {
      font-size: 14px;
    }
  }
}
.summary {
  &__text {
    font-size: 14px;
    margin-bottom: 0;
    @include mobile {
      font-size: 12px;
    }
    &-sm {
      @extend .summary__text;
      @include mobile {
        font-size: 13px;
        line-height: 18px;
      }
      span:not([class]) {
        line-height: 1px;
      }
    }
    &-xsm {
      @extend .summary__text;
      line-height: 120%;
      @include mobile {
        font-size: 10px;
      }
    }
  }
}

.Toastify__toast {
  font-size: 16px;
}
