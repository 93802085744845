.songbook {
  &__list {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    column-gap: 30px;

    @include tablet {
      grid-template-columns: repeat(6, 118px);
      column-gap: 19px;
    }

    &__container {
      max-width: calc(100vw - 32px);
      overflow: auto;
      margin-bottom: 32px;

      @include mobile {
        padding: 0 10px;
      }
    }

    .song__thumbnail__icon {
      @include mobile {
        width: 38px;
        height: 38px;
        padding: 12px;
        bottom: 10px;
        right: 10px;
        top: unset;
        left: unset;
        transform: unset;
      }
    }
  }

  &__series {
    overflow-x: scroll;
    overflow-y: hidden;
    height: 430px;
    white-space: nowrap;

    &__item {
      display: inline-block;
      height: 402px;
      width: 266px;
      border-radius: 20px;
      margin-right: 21px;
    }

    &::-webkit-scrollbar {
      width: 5px;
	  height: 8px;
    }

    &::-webkit-scrollbar-track {
      background: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
      background: #aaa;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #384753;
    }
  }
}
