.song {
  &__thumbnail {
    position: relative;
    border-radius: 10px;
    margin-bottom: 32px;
    @include mobile {
      margin-bottom: 0px;
    }
    &.nativities {
      width: 168px;
      height: 168px;
    }
    &__img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      border-radius: 10px;
    }
    &__icon {
      width: 70px;
      height: 70px;
      // background-color: $white;
      border-radius: 50%;
      // padding: 13px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 99;
      // box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.35);
      @include absolute-center;
      @include mobile {
        height: 80px;
        width: 80px;
        padding: 6px;
      }
      img {
        margin-left: 5px;
        @include mobile {
          margin-left: 2px;
        }
      }
      &__ready {
        animation-duration: 3s;
        animation-name: pulsate;
        animation-iteration-count: infinite;
      }
      &__playing {
        width: 50px;
        height: 50px;
      }
    }
  }
  &__title {
    font-size: 14px;
    font-weight: 600;
    @include laptop-sm {
      margin-bottom: 32px;
    }
    @include mobile {
      font-size: 10px;
      margin-bottom: 5px;
    }
  }
  &__details {
    @include mobile {
      display: inline;
      align-items: center;
      margin-bottom: 16px;
    }
  }
  &__grid {
    display: grid;
    grid-template-columns: repeat(6, max-content);
    column-gap: 24px;
    justify-content: space-between;
    &__item {
      &__title {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 0;
        max-width: 129px;
      }
    }
    &__thumb {
      width: 129px;
      height: 129px;
      border-radius: 10px;
      position: relative;
      margin-bottom: 30px;
      &__img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top left;
        border-radius: 10px;
      }
    }
    &__play {
      @include absolute-center;
      @include flex-center;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: $white;
      box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.35);
      img {
        margin-left: 8px;
      }
    }
  }
}

@keyframes pulsate {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
