aside {
  background-color: $arsenic;
  min-height: 100vh;
  width: 323px;
  transition: left 250ms ease-in-out;
  position: fixed;
  @include laptop-sm {
    position: fixed;
    left: -323px;
    z-index: 999;
    &.active {
      left: 0;
      width: 100%;
      background: rgba($color: #000000, $alpha: 0.5);
      .sidebar {
        background-color: $arsenic;
        min-height: 100vh;
        max-height: 100vh;
        overflow-y: scroll; /* has to be scroll, not auto */
        -webkit-overflow-scrolling: touch;
        // overflow: auto;
        width: 323px;
        display: flex;
        flex-direction: column;
        padding-bottom: 100px;
      }
    }
  }
  .sidebar {
    width: 323px;
    padding-bottom: 100px;
    &__logo-container {
      margin-bottom: 64px;
      @include laptop-sm {
        margin-bottom: 10px;
      }
    }
    &__logo {
      display: block;
      max-width: 178px;
      margin: 10px 24px 10px;
      @include laptop-sm {
        margin-bottom: 10px;
      }
    }
    &__list {
      list-style: none;
      margin: 0;
      padding: 0;
      &__item {
        display: flex;
        align-items: center;
        flex-direction: column;
        transition: 0.3s all;
        &:last-child {
          margin-bottom: 0;
        }
        &.active,
        &:hover,
        &:active {
          .sidebar__list__item__link {
            border-left: 5px solid $turquoise-medium;
            p {
              color: $white;
            }
          }
        }
        &__link {
          width: 100%;
          height: 42px;
          display: flex;
          align-items: center;
          padding-left: 23px;
          margin-bottom: 8px;
          border-left: 5px solid transparent;
          transition: 0.3s all;
          img {
            width: 22px;
            margin-right: 24px;
          }
          &:hover {
            color: $white;
          }
          p {
            font-size: 14px;
            font-weight: 500;
            line-height: 100%;
            color: $grey-chateau;
            margin-bottom: 0;
            margin-top: 4px;
          }
        }
        &.has-sublist {
          &.active {
            background: rgba($color: #000000, $alpha: 0.35);
            .sidebar__list__item__link {
              margin-bottom: 0;
            }
            .sidebar__list__item__sublist {
              height: auto;
              opacity: 1;
              padding: 20px 0 20px 25px;
            }
          }
        }
        &__sublist {
          list-style: none;
          width: 100%;
          margin: 0;
          padding: 0;
          height: 0;
          opacity: 0;
          overflow: hidden;
          transition: 0.3s all;
          &__item {
            display: flex;
            align-items: center;
            transition: 0.3s all;
            margin: 20px 10px 20px 50px;
            &:first-child {
              margin-top: 0;
            }
            &:last-child {
              margin-bottom: 0;
            }
            &__link {
              font-size: 12px;
              color: $white !important;
            }
          }
        }
        &.ss__only {
          display: none;
          @include laptop-sm {
            display: flex;
          }
        }
      }
    }
    &__profile {
      &__list {
        display: none;
        list-style: none;
        margin: 18px 0 10px;
        padding: 0;
        @include laptop-sm {
          display: block;
        }
        &__item {
          display: flex;
          align-items: center;
          padding: 2px 24px;
          &:first-child {
            padding-top: 0;
          }
          &:last-child {
            padding-bottom: 0;
          }
          img {
            width: 30px;
            height: 30px;
            border-radius: 100%;
            margin-right: 20px;
          }
          p {
            font-size: 11px;
            color: $white;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
.ring {
  -webkit-animation: ring 4s 0.7s ease-in-out infinite;
  -webkit-transform-origin: 50% 4px;
  -moz-animation: ring 4s 0.7s ease-in-out infinite;
  -moz-transform-origin: 50% 4px;
  animation: ring 4s 0.7s ease-in-out infinite;
  transform-origin: 50% 4px;
}

@keyframes ring {
  0% {
    transform: rotate(0);
  }
  1% {
    transform: rotate(30deg);
  }
  3% {
    transform: rotate(-28deg);
  }
  5% {
    transform: rotate(34deg);
  }
  7% {
    transform: rotate(-32deg);
  }
  9% {
    transform: rotate(30deg);
  }
  11% {
    transform: rotate(-28deg);
  }
  13% {
    transform: rotate(26deg);
  }
  15% {
    transform: rotate(-24deg);
  }
  17% {
    transform: rotate(22deg);
  }
  19% {
    transform: rotate(-20deg);
  }
  21% {
    transform: rotate(18deg);
  }
  23% {
    transform: rotate(-16deg);
  }
  25% {
    transform: rotate(14deg);
  }
  27% {
    transform: rotate(-12deg);
  }
  29% {
    transform: rotate(10deg);
  }
  31% {
    transform: rotate(-8deg);
  }
  33% {
    transform: rotate(6deg);
  }
  35% {
    transform: rotate(-4deg);
  }
  37% {
    transform: rotate(2deg);
  }
  39% {
    transform: rotate(-1deg);
  }
  41% {
    transform: rotate(1deg);
  }

  43% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(0);
  }
}
