.treatbox {
  &__heading {
    font-size: 42px;
    font-weight: 700;
    margin-bottom: 2px;
    @include mobile {
      font-size: 18px;
      margin-bottom: 8px;
    }
  }
  &__subheading {
    font-size: 18px;
    margin-bottom: 40px;
    margin-left: 34px;
    @include mobile {
      font-size: 12px;
      margin-bottom: 24px;
    }
  }
  &__modal_overlay {
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(21,168,129,.1);
    display: flex;
    position: fixed;
  }
  &__modal_overlay_surprise {
    background-color: rgba(0, 0, 0, .5)!important;
  }
  &__modal {
    height: 90%;
    aspect-ratio: 1.286;
    margin-top: 5vh;
    border: 1px solid grey;
    display: block;
    margin: auto;
    background-color: rgba(37,55,69,1);
    position: relative;
  }
  &__modal::before {
    content: '';
    z-index:0;
    position:absolute;
    left:0;
    top:0;
    background: no-repeat url('/img/treatbox-modal-bg.png');
    background-size: cover;
    width: 100%;
    height: 100%;
  }
  &__modal_dark::before {
    opacity:0.05;
  }
  &__modal_surprise {
    background-color: rgba(0, 0, 0, .9)!important;
    border: none!important;
  }
  &__modal_surprise::before {
    background-image: none !important;
  }
  &__surprised_animation_wrapper {
    margin-top: -25%;
    width: calc(100% - 100px);
    margin-left: 50px;
    position: relative;
  }
  &__surprised_animation_button_wrapper {
    width: 100%;
    position: absolute;
    bottom: 20px;
    display: flex;
  }
  &__surprised_animation_button {
    border-radius: 40px;
    width: 35%;
    height: 55px;
    margin: auto;
    background: #2AC678;
    font-size: 20px;
    font-weight: bold;
    padding-top: 5px;
  }
  &__surprise_audio{
    position: absolute;
    z-index: -1;
  }
  &__surprise_audio_loading{
    color: #cccccc;
    text-align: center;
    display: block;
    font-size: 2rem;
  }
  &__surprised_animation_play {
    position: absolute;
    top: 28%;
    right: 22%;
    width: 20%;
    aspect-ratio: 1;
    color: black;
    text-align: center;
    transform: rotate(10deg);
    border-radius: 8px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 60%;
  }
  &__surprised_animation_play a img{
    width: 60%;
    height: 60%;
  }
  &__surprised_animation_play>span{
    position: absolute;
    bottom: -35%;
    width: 100%;
    left: 0;
    color: black;
    font-size: 14px;
    font-weight: bold;
    height: 45px;
  }
  &__surprised_animation_play>span a{
    color: black;
  }
  &__modal_content {
    width: 100%;
    display: block;
    margin-top: 30%;
    position: relative;
  }
  &__modal_content_inner {
    width: 480px;
    display: block;
    margin: auto;
  }
  &__modal_close {
    position: absolute;
    font-size: 24px;
    right: 8px;
    top: 8px;
    color: black;
    width: 5%;
    aspect-ratio: 1;
  }
  &__modal_select_title {
    font-size: 36px;
    font-weight: 500;
    margin-bottom: 20px;
    text-align: center;
  }
  &__modal_button_go {
    font-size: 14px;
    font-weight: 400;
  }
}
